<div class="container-fluid">
<div class="row">

    <div class="col-sm-6 col-xl-3 col-lg-6"  >
      <div class="card o-hidden">
        <div class="b-r-4 card-body" [ngStyle]="{'background-color': colorScheme}">
          <div class="d-flex static-top-widget">
            <div class="align-self-center text-center">
              <i class="size-60 summary-chart-headers" data-feather="users"></i>
            </div>
            <div class="flex-grow-1 mediaBody">
              <h2 class="mb-0 counter summary-chart-headers">{{(data?.new ? data.new : 0) | number:'1.0':'en-ZA'}}</h2>
              <i class="icon-bg summary-chart-headers"
                data-feather="users"></i>
              <span class="m-0 text-uppercase summary-chart-headers">Total Consumers</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-6 col-xl-3 col-lg-6">
      <div class="card o-hidden">
        <div class=" b-r-4 card-body" [ngStyle]="{'background-color': colorScheme}" >
          <div class="d-flex static-top-widget">
            <div class="align-self-center text-center">
              <i  class="size-60 summary-chart-headers" data-feather="check-square"></i>
            </div>
            <div class="flex-grow-1 mediaBody">
              <h2 class="mb-0 counter summary-chart-headers">{{calcOptIns(data2) | number:'1.0':'en-ZA'}}</h2>
              <i class="icon-bg summary-chart-headers"
                data-feather="check-square"></i>
                <span   class="m-0 text-uppercase summary-chart-headers">Opted In</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-6 col-xl-3 col-lg-6">
      <div class="card o-hidden" >
        <div class=" b-r-4 card-body" [ngStyle]="{'background-color': colorScheme}" >
          <div class="d-flex static-top-widget">
            <div class="align-self-center text-center">
              <i  class="size-60 summary-chart-headers" data-feather="message-square"></i>
            </div>
            <div class="flex-grow-1 mediaBody">
                @if(data && data.new){
                  <h2  class="mb-0 counter summary-chart-headers">{{((data.returning + data.new) ?? 0)  | number:'1.0':'en-ZA'}}</h2>
                }@else {
                  <h2  class="mb-0 counter summary-chart-headers">0</h2><i class="icon-bg summary-chart-headers"
                  data-feather="message-square"></i>
                }
                <span class="m-0 text-uppercase summary-chart-headers">Total Entries</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-xl-3 col-lg-6">
      <div class="card o-hidden">
        <div class=" b-r-4 card-body" [ngStyle]="{'background-color': colorScheme}" >
          <div class="d-flex static-top-widget">
            <div class="align-self-center text-center">
              <!-- <app-feather-icons [icon]="'activity'"></app-feather-icons> -->
              <i  class="size-60 summary-chart-headers" data-feather="activity"></i>

            </div>
            <div class="flex-grow-1 mediaBody">
              <h2 class="mb-0 counter summary-chart-headers">{{data ? (calculateAvg() | number: '1.1-1' : 'en-ZA') : 0}}</h2><i  class="icon-bg summary-chart-headers"
                data-feather="activity"></i>
                <span  class="m-0 text-uppercase summary-chart-headers">Per Consumer</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>