<div class="container-fluid">
  <div class="page-header" [ngClass]="{'no-pad': padding }">
    <div class="row">
      <div class="col-lg-9">
        <div class="page-header-left">
          <h3>{{title}}</h3>
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="'/'">
                <app-feather-icons [icon]="'home'"></app-feather-icons>
              </a></li>
              <!-- <li class="breadcrumb-item active">{{breadcrumbs}}</li> -->
            <!-- <li class="breadcrumb-item" *ngIf="breadcrumbs?.parentBreadcrumb">{{breadcrumbs?.parentBreadcrumb}}</li>
            <li class="breadcrumb-item active" *ngIf="breadcrumbs?.childBreadcrumb">{{breadcrumbs?.childBreadcrumb}}
            </li> -->
          </ol>
        </div>
      </div>
      @if(back){
        <div class="col-lg-6 pull-right text-end text-right">
          <button type="button" [routerLink]="['/' + backLink]" class="btn btn-primary"><i class="fa fa-arrow-left"></i> Close</button>
        </div>
      }
      <!-- <div class="col-lg-6">
        <app-bookmark></app-bookmark>
      </div> -->
    </div>
  </div>
</div>