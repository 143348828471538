<div class="tab-container" style="min-height: 50%; padding-top: 10px;">
    <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav-tabs">
      <li [ngbNavItem]="1">
        <a ngbNavLink>Summary</a>
        <ng-template ngbNavContent>
            <div class="image-container">
                <img style="height: 350px; width: 360px;" src="../../../assets/images/papertrail/bottleTop.jpg" alt="Large Image" class="large-image">
                <div class="code-overlay">{{ data.Code }}</div> 
                
                <div class="image-details">
                    <div class="details-left">
                        <div><b>Name:</b> {{ data.firstName }}</div>
                        <div><b>Status:</b> {{ data.mobileNumber }}</div>
                    </div>
                    <div class="details-right">
                        <div><b>Date:</b> {{ data.createdAt ? formatDate(data.createdAt) : 'No Date Provided' }}</div>
                        <div><b>Mobile Number:</b> {{ data.moderationStatus }}</div>
                    </div>
                </div>
                
                <hr class="details-divider">
                
                <div class="additional-details">
                    <div><b>Encode status:</b> {{ data.entryStatus }}</div>
                    <div><b>Encode Bank:</b> {{ data.BankName }}</div>
                </div>
                
                <hr class="bottom-divider">
            </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="2">
        <a ngbNavLink>Raw Data</a>
        <ng-template ngbNavContent>
          <div style="height: 550px; width: 460px;"  class="raw-data-container">
            <pre>{{ data.entry | json }}</pre> 
          </div>
        </ng-template>
      </li>
    </ul>
    <div class="icon-container">
      <i class="material-icons download-icon" (click)=" downloadContent()">file_download</i>
      <i class="material-icons close-icon" (click)="closeView()">close</i>
  </div>
    <div [ngbNavOutlet]="nav"></div>
  </div>
  