import { BrowserModule } from "@angular/platform-browser";
import { NgModule, Optional, SkipSelf,APP_INITIALIZER,Injector } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { SharedModule, HttpLoaderFactory } from "./shared/shared.module";
import { AppRoutingModule } from "./app-routing.module";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from "@angular/common/http";
import { AppComponent } from "./app.component";
import { LoginComponent } from "./auth/login/login.component";
import { ToastrModule } from "ngx-toastr";
import { DecimalPipe } from "@angular/common";

import { AdminGuard } from "./shared/guard/admin.guard";
import { CookieService } from "ngx-cookie-service";
import "hammerjs";
import "mousetrap";

import { OAuthModule } from 'angular-oauth2-oidc';
import { OAuthService } from 'angular-oauth2-oidc';
import {OauthServiceInitializer} from './shared/services/auth/oauth-service-initializer';
import {HttpErrorInterceptor} from './shared/services/interceptors/http-error.interceptor';
import {TokenInterceptor} from './shared/services/interceptors/token.interceptor';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { CommonModule } from '@angular/common';
import {PaperTrailsComponent} from './components/paper-trails/paper-trails.component'
import {EntryDataComponent} from './entry-data/entry-data.component'
import { DatePipe } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import {AvatarModule } from 'ngx-avatars';

// import { UploadWidgetModule } from "@bytescale/upload-widget-angular";
// import {MomentDateFormatter} from './shared/utils/date-formatter';
// import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [AppComponent, LoginComponent],
  imports: [
    AvatarModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    SharedModule,
    AppRoutingModule,
    HttpClientModule,
    CommonModule,
    PaperTrailsComponent,
    EntryDataComponent,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),    
    OAuthModule.forRoot({
      resourceServer:{
        allowedUrls:[          
          'https://signin.techsys.co.za/realms/techsys',
          'https://',
          'http://',
        ],
        sendAccessToken:true,
      }
    }),
    //UploadWidgetModule,
  ],
  
  providers: [ 
    AdminGuard, 
    CookieService, 
    DecimalPipe,
    DatePipe,
   
    {
      provide: APP_INITIALIZER,
      useFactory:OauthServiceInitializer,
      deps:[OAuthService, Injector],
      multi:true,
    },
    {
      provide:HTTP_INTERCEPTORS,
      useClass:HttpErrorInterceptor,
      multi:true,
    },
     {
      provide:HTTP_INTERCEPTORS,
      useClass:TokenInterceptor,
      multi:true,
    },
     provideAnimationsAsync()
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA] ,
})
export class AppModule {}
