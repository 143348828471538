import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, map, of, Subject, Subscription, throwError } from 'rxjs';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { ProjectList } from '../data/tables/project-list';
import { environment } from '../../../environments/environment';
import { getServerErrors } from '../utils/get-server-errors';
import {DrawService} from './draw.service';
import * as XLSX from 'xlsx';
@Injectable({
  providedIn: 'root'
})
export class ProjectService implements OnDestroy {
  private exportConfigSource = new BehaviorSubject<any>({});
  exportConfig$ = this.exportConfigSource.asObservable();
  private entryData: any;
  private projectSource = new BehaviorSubject<any>(null);
  currentProject = this.projectSource.asObservable();
  private _busy$: BehaviorSubject<boolean>;
  private _projectLoading$: Subject<boolean>;
  private _drawTypeLoading$: Subject<boolean>;
  private _project$: BehaviorSubject<ProjectList | null>;
  private _projectDrawType$: BehaviorSubject<any | null>;
  private _error$: Subject<string | null>;
  private _submitted$: Subject<boolean | null>;
  private _created$: Subject<any | null>;
  private _drawSubmitting$: Subject<boolean>;
  private _draw$: BehaviorSubject<ProjectList | null>;
  private _drawList$: BehaviorSubject<any | null>;
  private _drawsLoading$: Subject<boolean>;
  private unsubscribe = new Subject<void>();
  private _drawDownloadLoading$: Subject<boolean>;
  private _drawEntries$ = new BehaviorSubject<any[]>([]);

public drawEntries$ = this._drawEntries$.asObservable();
  private _exportList$: BehaviorSubject<any | null>;
  private _exportLoading$: Subject<boolean>;
  private _hasExports = new BehaviorSubject<boolean>(false);
public hasExports$ = this._hasExports.asObservable();
private moderationStatusSource = new BehaviorSubject<string[]>([]);
public moderationStatuses$ = this.moderationStatusSource.asObservable();

  get busy$() { return this._busy$.asObservable() }
  get projectLoading$() { return this._projectLoading$.asObservable() }
  get drawTypeLoading$() { return this._drawTypeLoading$.asObservable() }
  get project$() { return this._project$.asObservable() }
  get projectDrawType$() { return this._projectDrawType$.asObservable() }
  get error$() { return this._error$.asObservable() }
  get submitted$() { return this._submitted$.asObservable() }
  get created$() {return this._created$.asObservable()}
  get draw$() {return this._draw$.asObservable()}
  get drawSubmitting$() {return this._drawSubmitting$.asObservable()}
  get drawList$() {return this._drawList$.asObservable()}
  get drawsLoading$() {return this._drawsLoading$.asObservable()}
  get drawDownloadLoading$() {return this._drawDownloadLoading$.asObservable()}

  get exportList$() {return this._exportList$.asObservable()}
  get exportLoading$() {return this._exportLoading$.asObservable()}
  
    constructor(private http: HttpClient) {    
    this._project$ = new BehaviorSubject<ProjectList | null>(null);
    this._projectDrawType$ = new BehaviorSubject<any | null>(null);
    this._error$ = new Subject<string | null>()
    this._submitted$ = new Subject<boolean | null>()
    this._projectLoading$ = new Subject<boolean>();
    this._drawTypeLoading$ = new Subject<boolean>();
    this._busy$ = new BehaviorSubject<boolean>(false);
    this._created$ = new Subject();

    this._draw$ = new BehaviorSubject<any | null>(null);
    this._drawSubmitting$ = new Subject<boolean>();
    this._drawList$ = new BehaviorSubject<any | null>(null);
    this._drawsLoading$ = new Subject<boolean>();
    this._drawDownloadLoading$ = new Subject<boolean>();

    this._exportList$ = new BehaviorSubject<any | null>(null);
    this._exportLoading$ = new Subject<boolean>();
  }

  save(form){
    this._busy$.next(true);
    this._submitted$.next(false);
    this.http.post<ProjectList>(`${environment.endpoints.api}projects`, form)
    .pipe(
      takeUntil(this.unsubscribe),
      finalize(() => this._busy$.next(false))
    )
    .subscribe({
      next: (response: any) => {
        this._submitted$.next(true);
        // this._project$.next(response);   
        this._created$.next(response);
      },
      error: (err:any) => {
        const error: string = getServerErrors(err);
        this._error$.next(error);
      },
      }
    );
  } 

  updateProject(project: any) {
    this.projectSource.next(project);
  }

  update(form){
    this._busy$.next(true);
    this._submitted$.next(false);
    this.http.patch<ProjectList>(`${environment.endpoints.api}projects/${form.id}`, form)
    .pipe(
      takeUntil(this.unsubscribe),
      finalize(() => this._busy$.next(false))
    )
    .subscribe({
      next: (response: any) => {
        this._submitted$.next(true);
        this._project$.next(response);        
      },
      error: (err:any) => {
        const error: string = getServerErrors(err);
        this._error$.next(error);
      },   
        
      }
    );
  }

  fetch(id:string, token?:string) {
    this._project$.next(null),
    this._projectLoading$.next(true);
    return this.http.get<ProjectList>(`${environment.endpoints.api}projects/${id}?token=${token}`)
    .pipe(
      finalize(() => this._projectLoading$.next(false))
    )
    .subscribe({
        next: (response: any) => this._project$.next(response),
        error: (err:any) => {
          const error: string = getServerErrors(err);
          this._error$.next(error);
        },
      }
    );
  }


  getDrawType(id: string, token?:string) {
    // this._projectDrawType$.next(null);
    this._drawTypeLoading$.next(true);
    return this.http.get<any>(`${environment.endpoints.api}projects/${id}/draw/types?token=${token}`)
      .pipe(
        finalize(() => this._drawTypeLoading$.next(false))
      )
      .subscribe({
        next: (response: any) => {
          this._projectDrawType$.next(response);
          console.log(response);
        },
        error: (err: any) => {
          const error: string = getServerErrors(err);
          this._error$.next(error);
        }
      });
  }

  newDraw(form, id, token?: string): Observable<any> {
    this._drawSubmitting$.next(true);
    return this.http.post<ProjectList>(`${environment.endpoints.api}projects/${id}/draws?token=${token}`, form).pipe(
      takeUntil(this.unsubscribe),
      finalize(() => this._drawSubmitting$.next(false)),
      tap({
        next: (response: any) => {
          this._draw$.next(response);
          console.log('Draw saved successfully:', response);
        },
        error: (error: any) => {
          console.error('Failed to save draw:', error);
          this._error$.next('Failed to save draw');
        }
      }),
      catchError(error => {
        console.error('Error in newDraw:', error);
        return throwError(() => new Error('Error during draw creation'));
      })
    );
}

updateDrawList(newDraw: any) {
  let currentDraws = this._drawList$.getValue() || [];
  currentDraws.push(newDraw);  // Add new draw to the list
  this._drawList$.next(currentDraws);  // Emit the updated list
}
  // newDraw(form, id){
  //   this._drawSubmitting$.next(true);

  //   this.http.post<ProjectList>(`${environment.endpoints.api}projects/${id}/draws`, form)
  //   .pipe(
  //     takeUntil(this.unsubscribe),
  //     finalize(() => this._drawSubmitting$.next(false))
  //   )
  //   .subscribe({
  //     next: (response: any) => {
  //       // this._project$.next(response);   
  //       this._draw$.next(response);
  //       console.log(response)
  //     },
  //     error: (err:any) => {
  //       const error: string = getServerErrors(err);
  //       this._error$.next(error);
  //     },
  //     }
  //   );
  // } 

getPoolSize(drawId: string): Observable<any> {
  const url = `https://easydash-uat.brzn.co.za/api/draws/${drawId}/pool-size`;
  return this.http.get(url).pipe(
    catchError(error => {
      console.error('Failed to fetch pool size:', error);
      return throwError(() => new Error('Failed to fetch pool size'));
    })
  );
}


  drawList(id: string, token?: string, skip = 0, take = 500) {
    this._drawsLoading$.next(true);
    return this.http.get<any>(`${environment.endpoints.api}projects/${id}/draws?token=${token}&skip=${skip}&take=${take}`)
      .pipe(
        finalize(() => this._drawsLoading$.next(false)),
        map(response => {
          response.items.forEach(item => {
            item.hasError = item.error ? true : false;
            if (item.hasError) {
              const winnerMatch = item.error.match(/Number of Winners: \[(\d+)\]/);
              item.numberOfWinners = winnerMatch ? parseInt(winnerMatch[1], 10) : 0;
            } else {
              item.numberOfWinners = null;
            }
          });
          response.items.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
          return response.items;
        })
      )
      .subscribe({
        next: (items: any[]) => {
          this._drawList$.next(items);
        },
        error: (error: any) => {
          console.error("Error fetching draws:", error);
          this._drawList$.error(error);
        }
      });
  }


  

  
  drawList1(id, token?: string, skip = 0, take = 500) {
    this._drawsLoading$.next(true);
    return this.http.get<any>(`${environment.endpoints.api}projects/${id}/draws?token=${token}&skip=${skip}&take=${take}`)
    .pipe(
      finalize(() => this._drawsLoading$.next(false)),
      tap(response => {
        this._drawList$.next(response.items);
        this._drawEntries$.next(response.items); // Save the draw entries
        console.log("Draw Response:", response);
      }),
      catchError(error => {
        console.error("Error during draw list fetch:", error);
        this._drawList$.error(error);
        return throwError(() => new Error("Failed to fetch draw entries"));
      })
    );
}

  


  exportList(id, token?:string) { 
    this._exportLoading$.next(true);
    return this.http.get<any>(`${environment.endpoints.api}projects/${id}/exports?orderBy=processedAt&orderDirection=desc&token=${token}`)
    .pipe(
      finalize(() => this._exportLoading$.next(false))
    )
    .subscribe({
        next: (response: any) => {
          const hasExports = response.items && response.items.length > 0;
          this._hasExports.next(hasExports);
          this._exportList$.next(response.items);
          console.log('Has exports:', hasExports);
          console.log(response.items,)
         this._exportList$.next(response.items)
        },
        error: (err:any) => {
          const error: string = getServerErrors(err);
          this._error$.next(error);
        },
      }
    );
    
  }

  export(draw, id, token?:string){
  
    this._drawDownloadLoading$.next(true);
    this.http.post<any>(`${environment.endpoints.api}projects/${id}/exports?token=${token}`, draw)
    .pipe(
      finalize(() => this._drawDownloadLoading$.next(false))
    )
    .subscribe({
        next: (response: any) => {
          // console.log(response,'THE RESPONSE DRAW')
        this.queue(response.id, token)
        console.log(draw,'LOG DRAW');

        // console.log('Export draw id', response.id)
        // console.log('Export was successful!', 'Success');
          //return response
          // let _res = JSON.stringify(response);
          // const fileName = `${draw.file.name}`;
          // const blob = new Blob([_res], { type: "application/json" });

          //   const downloadUrl = window.URL.createObjectURL(blob);
          //   const link: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
          //   link.href = downloadUrl;
  
          //   link.setAttribute('download', fileName);
          //   document.body.appendChild(link);
          //   link.click();
  
          //   document.body.removeChild(link);
          //   window.URL.revokeObjectURL(downloadUrl);
        },
        error: (error:any) => {
          console.error('Error during export:', error);
          this._error$.next(error);
      }
            
      }
    );
  }

  queue(id:string, token?:string) {
    return this.http.patch<any>(`${environment.endpoints.api}exports/${id}/queue?token=${token}`, {})
    .pipe(
      takeUntil(this.unsubscribe),      
    )
    .subscribe({
      next: (response: any) => {    
        console.log('Patch response',response)    
        // this._queue$.next(true);
        return;
      },
      error: (err:any) => {
        const error: string = getServerErrors(err);
        this._error$.next(error);
      },           
      }
    );
  }

  exportEntries(entries, id, token?:string){
    this._drawDownloadLoading$.next(true);
    this.http.post<any>(`${environment.endpoints.api}projects/${id}/exports?token=${token}`, entries)
    .pipe(
      finalize(() => this._drawDownloadLoading$.next(false))
    )
    .subscribe({
        next: (response: any) => {
  
        this.queue(response.id, token)
        console.log('Export draw id', response)
        console.log('Export was successful!', 'Success');
          //return response
          // let _res = JSON.stringify(response);
          // const fileName = `${draw.file.name}`;
          // const blob = new Blob([_res], { type: "application/json" });
  
          //   const downloadUrl = window.URL.createObjectURL(blob);
          //   const link: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
          //   link.href = downloadUrl;
  
          //   link.setAttribute('download', fileName);
          //   document.body.appendChild(link);
          //   link.click();
  
          //   document.body.removeChild(link);
          //   window.URL.revokeObjectURL(downloadUrl);
        },
        error: (error:any) => this._error$.error(error),       
      }
    );
  }



  queueEntries(id:string, token?:string) {
    return this.http.patch<any>(`${environment.endpoints.api}exports/${id}/queue?token=${token}`, {})
    .pipe(
      takeUntil(this.unsubscribe),      
    )
    .subscribe({
      next: (response: any) => {        
        // this._queue$.next(true);
        return;
      },
      error: (err:any) => {
        const error: string = getServerErrors(err);
        this._error$.next(error);
      },           
      }
    );
  }


  downloadExport(fileExport, token?:string){
    return this.http.get(fileExport.file['_links'].download.href+`?filename=${fileExport.file.name}.zip&token=${token}`, {
      responseType: 'arraybuffer'
    })
    .pipe(
      finalize(() => {})
    )
    .subscribe({
        next: (response: any) => {
    
          let _res =response;
          const fileName = `${fileExport.file.name}.zip`;
          const blob = new Blob([_res], { type: "application/zip" });

            const downloadUrl = window.URL.createObjectURL(blob);
            const link: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
            link.href = downloadUrl;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(downloadUrl);
        },
        error: (error:any) => {
          debugger
          console.log(error)
        },       
      }
    );
  }


  SaveExport(items) {
    console.log("All items received:", items); 

    if (!items || !items.length) {
        console.error('Invalid export object:', items);
        return;
    }

    const validItems = items.filter(item => item.consumer);
    console.log("Valid items after filtering:", validItems); 

    if (!validItems.length) {
        console.error('No valid items to export:', items);
        return;
    }

    const exportData = validItems.map(item => ({
        "First Name": item.consumer.firstName || '',
        "Date": item.consumer.createdAt || '', 
        "Mobile Number": item.consumer.mobileNumber || '', 
        "Status": item.moderationStatus || '',
        "Image": item.payload ? item.payload.imageurl || '' : '',
        "last Name": item.consumer.lastName 
    }));

    const ws = XLSX.utils.json_to_sheet(exportData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Consumer Details");

    const wbout = XLSX.write(wb, { type: 'binary', bookType: 'xlsx' });
    const blob = new Blob([this.s2ab(wbout)], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    const fileName = `Consumer_Details.xlsx`;
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(link.href);
}

private s2ab(s: string): ArrayBuffer {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
}

fetchModerationStatuses(token?:string) {
  this.http.get<string[]>(`${environment.endpoints.api}entries/moderation-statuses?token=${token}`)
    .pipe(finalize(() => {}))
    .subscribe({
      next: (statuses) => {
        this.moderationStatusSource.next(statuses);
        console.log('Statuses:' ,statuses)
      },
      error: (error) => {
        console.error('Failed to fetch moderation statuses:', error);
        this.moderationStatusSource.next([]);
      }
    });
}
  
  getProjectById(projectId: string): Observable<{ userId: string, colorScheme: string }> {
    return this.http.get(`${environment.endpoints.api}projects/${projectId}/entries`).pipe(
      map((data: any) => {
        console.log('Response data:', data); 
        if (data && data.userId && data.colorScheme) {
          console.log('User ID:', data.userId);
          // console.log('Color Scheme:', data.colorScheme);
          return {
            userId: data.userId,
            colorScheme: data.colorScheme
          };
        } else {
          throw new Error('User ID or Color scheme not found in response data.');
        }
      }),
      catchError((error) => {
        console.error('Error fetching project:', error);
        return throwError('Failed to fetch project.');
      })
    );
  }
  

getProjectEntries(projectId: string, params:any, token?:string): Observable<any> {

  return this.http.get(`${environment.endpoints.api}projects/${projectId}/entries?take=${params.take}&skip=${params.skip}&orderBy=${params.orderBy}&orderDirection=${params.orderDirection}&moderationStatus=${params.moderationStatus}&mobileNumber=${params.mobileNumber}&token=${token}`);
}

getDrawEntries(drawId: string, params:any, token?:string): Observable<any> {

  return this.http.get(`${environment.endpoints.api}draws/${drawId}/entries?take=${params.take}&skip=${params.skip}&orderBy=${params.orderBy}&orderDirection=${params.orderDirection}&moderationStatus=${params.moderationStatus}&mobileNumber=${params.mobileNumber}&token=${token}&search="jsh"`);
}



getConsumerEntries(projectId: string): Observable<any> {
  const url = `${environment.endpoints.api}projects/${projectId}/consumers?Take=5000`;
  return this.http.get(url);
}

    destroy(){   
    this._busy$.next(false);
    this._project$.next(null);
    this._projectLoading$.next(false)    
    this._error$.next(null)
    this._submitted$.next(false)
  }

  ngOnDestroy(): void {

    this.unsubscribe.next();
    this.unsubscribe.complete();
      this._project$.unsubscribe();
      this._busy$.unsubscribe();      
      this._projectLoading$.unsubscribe();
      this._error$.unsubscribe()
      this._submitted$.unsubscribe();
      this._created$.unsubscribe();
  }
  
   ngOninit(){
     this.fetchModerationStatuses();
   }

  

   setEntryData(data: any) {
     this.entryData = data;
   }
 
   getEntryData() {
     return this.entryData;
   }

   getProfileProperties(id: string, token?:string): Observable<string[]> {
    const apiUrl = `${environment.endpoints.api}projects/${id}/consumer/profile-properties?token=${token}`;
    return this.http.get<string[]>(apiUrl);
  }

  getValuesByKey(key: string,id: string,): Observable<string[]> {
    // This would be an actual HTTP call in a real application
    return this.http.get<string[]>(`${environment.endpoints.api}projects/${id}/entries/meta-data/values?key=${key}`);
  }

  updateProjectConfiguration(id: string, data: any): Observable<any> {
    const url = `${environment.endpoints.api}projects/${id}/configuration/rewards`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.put(url, data, { headers });
  }

  meta(id: string): Observable<string[]> {
    const apiUrl = `${environment.endpoints.api}projects/${id}/entries/meta-data`;
    return this.http.get<string[]>(apiUrl);
  }


  getMetadataFields(id: string, token?:string): Observable<string[]> {
    const url = `${environment.endpoints.api}projects/${id}/entries/meta-data?token=${token}`;
    return this.http.get<string[]>(url);
  }

  getMetadataValues(key: string, id: string): Observable<string[]> {
    const url = `${environment.endpoints.api}projects/${id}/entries/meta-data/values?key=${key}`;
    return this.http.get<string[]>(url);
  }

  updateExportConfig(config: any) {
    this.exportConfigSource.next(config);
  }
  // exporT(selectedEntries: any[], projectId: string): Observable<any> {
  //   const body = {
  //     conbfiguration: selectedEntries.map(entry => entry.id), // Sending the selected entries' IDs
  //     type: 'draws',
  //     name: 'Selected Draw Entries Export',
  //     description: 'Export of selected or visible draw entries'
  //   };
  
  //   return this.http.post<any>(`${environment.endpoints.api}projects/${projectId}/exports`, body)
  //     .pipe(
  //       takeUntil(this.unsubscribe),
  //       tap((response: any) => {
  //         this.queue(response.id); 
  //       })
  //     );
  // }
  
 
}