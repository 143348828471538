<!-- Container-fluid starts-->
<div class="container-fluid">

  <div *ngIf="!showManageWinners"  class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header">
          <div class="d-flex"> 
            <h5 class="d-flex" style="flex:auto">PAST DRAWS</h5> 
            
            
            <div  class="pull-right text-end text-right">
              <button [ngStyle]="{'background-color': colorScheme}"  type="button" (click)="onDraw(null)" class="pull-right text-end text-right btn me-1 button-test">New Draw</button> 
            <!-- <button [ngStyle]="{'background-color': colorScheme}" type="button" 
              (click)="download()" class="pull-right text-end text-right btn me-1 button-test"
              *ngIf="hasData">
              Export All Winners
            </button> -->

            </div>

            <!-- <div *ngIf="isLoading" class="loading-overlay">
              <mat-spinner [diameter]="30"></mat-spinner>
            </div> -->

      </div>
         <hr/>
          <div class="row">
            <div class="col-sm-12">
                <div class="card">
                    <div class="table-responsive">
                      <ngx-datatable  class="material" #table [rows]="(drawListItem$ | async)" [limit]="10" 
                            [headerHeight]="50"
                            [footerHeight]="50" 
                            [columnMode]="ColumnMode.flex"
                            rowHeight="auto">
                      <ngx-datatable-column [flexGrow]="1" prop="name" name="Date/Time">
                        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                          {{row.configuration.entriesFrom | date: 'dd MMMM yyyy'}} - {{row.configuration.entriesUntil | date: 'dd MMMM yyyy'}}
                        </ng-template>
                      </ngx-datatable-column>
                      <ngx-datatable-column [flexGrow]="1" prop="drawType" name="Type"></ngx-datatable-column>
                      <ngx-datatable-column [flexGrow]="1" prop="name" name="Label"></ngx-datatable-column>
                      <ngx-datatable-column [flexGrow]="1" prop="id" name="Winner Count">
                        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                          <ng-container *ngIf="row.numberOfWinners === 0 || row.drawnAt === null; else showWinners">
                            <mat-icon style="color: red; margin-left: -1px;" 
                                      [matTooltip]="row.hasError ? 'Error: ' + row.error : 'Draw not performed yet, Click edit to perform the draw'" 
                                      matTooltipPosition="above">warning</mat-icon>
                          </ng-container>
                        
                          <ng-template #showWinners>
                            <span>{{ row.numberOfWinners || row.configuration.numberOfEntries}}</span>/<span>{{ row.configuration.numberOfEntries }}</span>
                          </ng-template>
                        </ng-template>
                        

                      </ngx-datatable-column>
                      
                      <ngx-datatable-column [flexGrow]="1" prop="id" name="Actions">
                        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                          <!-- Show Edit button if draw is not performed -->
                          <button 
                          class="draw-action-button edit"
                            style="color: white" 
                            [ngStyle]="{'background-color': colorScheme}" 
                            *ngIf="row.drawnAt === null" 
                            (click)="onEditDraw(row)">
                            Edit Draw
                          </button>
                      
                          <!-- Show Manage Winners button if draw is performed -->
                          <button 
                          *ngIf="row.drawnAt !== null"  
                            class="draw-action-button manage btn" 
                            style="color: white" 
                            [ngStyle]="{'background-color': colorScheme}"  
                            (click)="toggleManageWinners(row)" 
                            [disabled]="row.numberOfWinners === 0">
                            Manage Winners
                          </button>
                        </ng-template>
                      </ngx-datatable-column>
                      
                      
                      </ngx-datatable>

                      <div class="spinner-container" *ngIf="drawListLoading$ | async">
                        <app-chart-load ></app-chart-load>
                      </div>
                        <!-- <table class="table">
                            <thead>
                              <tr class="alig d-flex">
                                    <th style="flex:1;display:flex;justify-content: flex-start; align-items: center;" scope="col">Date/Time</th>
                                    <th style="flex:1;display:flex;justify-content: flex-start; align-items: center;" scope="col">Type</th>
                                    <th style="flex:1;display:flex;justify-content: flex-start; align-items: center;" scope="col">Label</th>
                                    <th style="flex:1;display:flex;justify-content: flex-start; align-items: center;" scope="col">Winner Count</th>                              
                                    <th style="flex:1;display:flex;justify-content: flex-start; align-items: center;" scope="col">Actions</th>
                                </tr>
                            </thead>                        
                              <tbody>
                                
                                @for(draw of drawListItem$ | async; track draw?.id){
                                  <tr class="alig d-flex">
                                    <td style="flex:1; display:flex;justify-content: flex-start; align-items: center;" scope="row">
                                      {{draw.configuration.entriesFrom | date: 'dd MMMM yyyy'}} - {{draw.configuration.entriesUntil | date: 'dd MMMM yyyy'}}
                                    </td>
                                    <td style="flex:1;display:flex;justify-content: flex-start; align-items: center;">{{draw.drawType}}</td>
                                    <td style="flex:1;display:flex;justify-content: flex-start; align-items: center;">{{draw.name}}</td>
                                    <td style="flex:1;display:flex;justify-content: flex-start; align-items: center;">
                                      {{draw.configuration.numberOfEntries}}
                                    </td>   
                                    <td>
                                      <button class="btn btn-primary" (click)="download(draw)">Export winners</button>
                                    </td>                         
                                  </tr>        
                                }  
                                @empty { 
                                  @if(drawListLoading$ | async){
                                    <app-chart-load class="margin-auto d-flex"></app-chart-load>
                                  }@else {
                                    <app-no-result></app-no-result> 
                                  }                              
                                }
                              </tbody>                                             
                        </table> -->
                    </div>
                </div>
            </div>
        </div>
        </div>
                
    </div>
  </div>

</div>
<div class="spinner-container" *ngIf="isLoading">
  <app-chart-load ></app-chart-load>
</div>
<!-- Container-fluid Ends-->
<app-manage-winners 
[token]="token"
*ngIf="showManageWinners" 
[drawId]="selectedDrawId || ''" 
[drawName]="selectedDrawName || ''" 
[numberOfEntries]="selectedDrawEntries || 0" 
[drawConfig]="selectedDrawConfig || ''" 
(close)="toggleManageWinner()">
</app-manage-winners>
