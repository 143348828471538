<div class="container-fluid">
    <div class="col-sm-12">
      <div class="card">
        <div class="Entry-daily-header" id="chart" [ngStyle]="{'background-color': colorScheme}" ><h6 class="m-0">Entry Status Daily</h6></div>         
        @if((data?.length > 1)){
          <google-chart id="areaChart2" [data]="areaChart2"></google-chart>                          
        }@else{
          <h4 class="text-center p-t-25 p-b-25">No Available Data Found.</h4>
        }        
      </div>
      <!-- {{data | json}} -->
    </div>
</div>