import { Component, OnInit, ViewEncapsulation,Input } from '@angular/core';
import { ColorService } from '../../../../shared/services/theme.service';
import { ActivatedRoute } from '@angular/router'

@Component({
  selector: 'app-consumer-targets',
  templateUrl: './consumer-targets.component.html',
  styleUrls: ['./consumer-targets.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ConsumerTargetsComponent implements OnInit {
  @Input() data;
  @Input() data2;
  @Input() data3;
  @Input() project;
  colorScheme: string;

  constructor(private colorService: ColorService,private route: ActivatedRoute) { }


  ngOnInit() {
    this.colorScheme = this.project.colorScheme;
    console.log(this.project.targets,'Targets')
  }
 
  calcPercent(target,value){ 
    if(isNaN(target) || isNaN(value)) return 0;
    return (value / target) * 100 
  }
}
