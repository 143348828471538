import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import * as chartData from '../../../../shared/data/chart/google-chart';
import { ColorService } from '../../../../shared/services/theme.service';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-prize-types-daily-chart',
  templateUrl: './prize-types-daily.component.html',
  styleUrls: ['./prize-types-daily.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PrizeTypesDailyComponent implements OnInit {
  @Input() data;
  @Input() loading;
  @Input() project;
  

  lineChart:any;
  chartData;
  colorScheme: string;
  private _chartTheme = [];
  
  constructor(private colorService: ColorService, private route: ActivatedRoute) { }

  private filterData(){
    // this.chartData = this.data.map(res => {    
    //   return [res.sampledAt, res.total]
    // });

    // let _header = this.data.map(res => {    
    //   return [res.reward.name]
    // });

   
    // this.chartData.unshift(_header);

    this.lineChart = {
      chartType: 'LineChart',
      dataTable: this.data,
      options: {
        chart: {
          title: '',
          subtitle: ''
        },
        title: '',
        curveType: 'function',
        lineWidth:4,
        fontName: "Roboto, 'Helvetica Neue', 'sans-serif'",
        fontSize: 13,
        vAxis: { minValue: 0 , format: 'short' },
        subtitle: 'in millions of dollars (USD)',
        colors: this._chartTheme,
        fullWidth: true,    
        height: 400,
        backgroundColor:'transparent',
        // curveType: 'function',
        legend: { position: 'bottom' },
        chartArea:{left:'2.5%',top:'5%',width:'93%',height:'70%'}

      
      },
    };
  }

  ngOnInit() {
    // alert(this.statistics)
    if(!this.data) return;
    this.setChartTheme();
  }

  private setChartTheme(){
    this.colorScheme = this.project.colorScheme;
    this._chartTheme = this.colorService.chartTheme(this.project.colorScheme);    
    this.filterData();
  }
}
