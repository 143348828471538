<div class="container-fluid">
    <div class="col-sm-12">
      <div class="card">
        <div  class="Opt-ins-header"  [ngStyle]="{'background-color': colorScheme}" ><h6 class="m-0">Consumer Opt-ins</h6></div>             
        @if((data?.length > 1)){
          <google-chart [data]="lineChart"></google-chart>                      
        }@else{
          <h4 class="text-center p-t-25 p-b-25">No Available Data Found.</h4>
        }    
      </div>
    </div>
</div>