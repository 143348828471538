import { Component, OnInit, Input, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Observable, Subscription, Subject, takeUntil } from 'rxjs';
import { NgbActiveModal, NgbModal, ModalDismissReasons, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ValidatorFn, FormBuilder, FormGroup, Validators, FormGroupName, FormControl } from '@angular/forms';
import { BrandsList } from '../../../shared/data/tables/brands-list';
import {IDraw} from '../../../shared/data/tables/draw';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { NgxDropzoneChangeEvent } from 'ngx-dropzone';
import {BrandService} from '../../../shared/services/brand.service';
import {FileService} from '../../../shared/services/file.service';
import { ToastrService } from 'ngx-toastr';
import { NgbDateStruct, NgbDate, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { UploadWidgetConfig, UploadWidgetResult, UploadWidgetOnUpdateEvent } from '@bytescale/upload-widget';
import {ProjectService} from '../../../shared/services/project.service';
import {Router, ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-draw-modal',
  templateUrl: './draw-modal.component.html',
  styleUrls: ['./draw-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DrawModalContent implements OnInit,OnDestroy {
  @Input() data;
  @Input() id;

  brandData;
  drawForm;
  submitted = false;
  _uploadedFile = undefined;
  model: NgbDateStruct;
  date: { year: number, month: number };
  modelPopup
  modelDisabled: NgbDateStruct;
  disabled = true;
  modelCustom: NgbDateStruct;
  displayMonths = 2;
  navigation = 'select';
  showWeekNumbers = false;
  outsideDays = 'visible';
  hoveredDate: NgbDate | any;
  fromDate: NgbDate;
  toDate: NgbDate | any;
  modelFooter: NgbDateStruct;
  today = this.calendar.getToday();


  destroy$: Subject<boolean> = new Subject()
  project$: Observable<any | null>;
  projectItem$: Subscription;
  projectLoading$: Observable<boolean>;
  error$:Observable<any>;
  projectDrawType$:  Observable<any | null>;;
  drawTypeLoading$: Observable<boolean>;

  
  draw$:  Observable<any | null>;;
  drawSubmitting$: Observable<boolean>;

  get configuration(){return (this.drawForm.get('configuration') as FormGroup)}

  ngOnInit() {
  
    if(this.id) this.projectService.getDrawType(this.id);

    this.draw$.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      if(!res) return;
      this.submitted = false;
      this.activeModal.close();
      this.toastrService.success('Draw Created')
    })

    this.error$.pipe(takeUntil(this.destroy$)).subscribe(err => {
      if(!err) return;
      this.toastrService.error(err, undefined, { timeOut: 3000, positionClass: 'toast-bottom-right'});
    })

    this.initForm(null)

  }

  constructor(config: NgbModalConfig,               
              private formBuilder: FormBuilder,  
              private brandService:BrandService,  
              public activeModal: NgbActiveModal,
              private toastrService: ToastrService,   
              private calendar: NgbCalendar,
              private projectService: ProjectService,
              private route:ActivatedRoute,
              ) {
                this.projectDrawType$ = this.projectService.projectDrawType$; 
                this.drawTypeLoading$ = this.projectService.drawTypeLoading$;
                this.draw$ = this.projectService.draw$; 
                this.drawSubmitting$ = this.projectService.drawSubmitting$;
                this.error$ = this.projectService.error$;
              }

  private initForm(data?){
    this.drawForm = this.formBuilder.group({
      id: [data? data.id: ''],
      name: [ data? data.name : '', [Validators.required, Validators.minLength(3)]],           
      description: [data? data.description : ''],
      drawType: [data? data.drawType : '',[Validators.required]],
      configuration: this.formBuilder.group({
        numberOfEntries:[data? data.description : '',[Validators.required, Validators.min(1)]],
        entriesFrom: [Date.parse(data?.configuration.entriesFrom) ?? new Date()],
        entriesUntil: [Date.parse(data?.configuration.entriesUntil) ?? new Date()],
      })
    });
  }

  onSubmit(){
    this.submitted = true;
    this.dateChange();
    if(!this.drawForm.valid) return;  
    return this.save();    
  }

  dateChange(){
    this.configuration.controls['entriesFrom'].setErrors({'invalid': null})
    this.configuration.controls['entriesFrom'].updateValueAndValidity();
    this.configuration.controls['entriesUntil'].setErrors({'invalid': null})
    this.configuration.controls['entriesUntil'].updateValueAndValidity();

    let _sta:any = this.startDate?.value;
    let _start:any = `${_sta?.year}-${_sta?.month}-${_sta?.day}`;

    let _en:any = this.endDate?.value;
    let _end:any = `${_en?.year}-${_en?.month}-${_en?.day}`;

    this.configuration.controls['entriesFrom'].setValue(new Date(_start));
    this.configuration.controls['entriesUntil'].setValue(new Date(_end));

    if(!_sta) {this.configuration.controls['entriesFrom'].setErrors({'invalid': true})}
    if(!_en) {this.configuration.controls['entriesUntil'].setErrors({'invalid': true})}

    if(this.configuration.controls['entriesUntil'].value < this.configuration.controls['entriesFrom'].value){
      this.configuration.controls['entriesFrom'].setErrors({'invalid': true})
      this.configuration.controls['entriesUntil'].setErrors({'invalid': true})
        return this.toastrService.error('End date cannot be earlier than Start date.')
    } 
    return;
  }

  save(){
    this.projectService.newDraw(this.drawForm.value, this.id);
    this.submitted = false;
    
  }

  update(){
    this.brandService.update(this.drawForm.value);
    this.submitted = false;
  }

  onCancel(){
    this.drawForm.reset();
    this.activeModal.close(true)
  }

  startDate = new FormControl({}, (control: FormControl | any) => {
    const value = control.value;

    if (!value) return null;
    return null;
  });

  endDate = new FormControl({}, (control: FormControl | any) => {
    const value = control.value;
    if (!value) return null;
    return null;
  });

  isDisabled = (date: NgbDate, current: {month: number}) => date.month !== current.month;
  isWeekend = (date: NgbDate) =>  this.calendar.getWeekday(date) >= 6;

  onDateSelection(date: NgbDate | any) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)}
  isInside(date: NgbDate) {return date.after(this.fromDate) && date.before(this.toDate)}
  isRange(date: NgbDate) {return date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date)}

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}

