import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-result',
  templateUrl: './no-results.component.html',
  styleUrls: ['./no-results.component.scss']
})
export class NoResultComponent implements OnInit {

  constructor() {}

  ngOnInit() {}
c
}
