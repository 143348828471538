import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { envLoader } from './environments/environment-loader';
import { Environment } from './environments/environment.interface';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

envLoader.then((env: Environment) => {
    environment.production = env.production;
    environment.endpoints = env.endpoints;
    environment.authentication = env.authentication;
    environment.storeKey = env.storeKey;

    if (environment.production) {
        enableProdMode();
    }
    platformBrowserDynamic().bootstrapModule(AppModule).catch(err => console.error(err));
});
