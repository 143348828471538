
import { OAuthService, AuthConfig, LoginOptions } from 'angular-oauth2-oidc';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { UserExtensions } from './user-extensions';


export function AppAuthConfig(): AuthConfig {
  // return {
  //   issuer: environment.endpoints.authority,
  //   redirectUri: window.location.origin + '/index.html',
  //   silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
  //   postLogoutRedirectUri: window.location.origin,
  //   clientId: environment.authentication.appId,
  //   dummyClientSecret: environment.authentication.appSecret,
  //   responseType:'code',
  //   scope: environment.authentication.scopes,
  //   showDebugInformation: environment.authentication.showDebug,
  //   sessionChecksEnabled: environment.authentication.enableSessionChecks,
  //   requireHttps: environment.authentication.requireHttps,
  //   silentRefreshShowIFrame: environment.authentication.silentRefreshShowIFrame,
  //   skipIssuerCheck: environment.authentication.skipIssuerCheck,
  //   oidc: true,
  //   silentRefreshTimeout:1000
  // };

  return {
    issuer: environment.endpoints.authority,  
    redirectUri: window.location.origin + '/index.html',
    clientId: environment.authentication.appId,
    scope: environment.authentication.scopes,
    silentRefreshTimeout:60000
  }
}

export interface AuthInitializeResponse {
  loggedIn: boolean;
  state: string;
}

export class AuthenticationExt {

  public static Configure(auth: OAuthService) {

    const cfg = AppAuthConfig();
    auth.configure(cfg);
    auth.authorizationHeader()
    auth.setupAutomaticSilentRefresh();
    auth.setStorage(sessionStorage);
    return {
      initialize: () => AuthenticationExt.initialize(auth),
      // enableAutoTokenRefresh: () => AuthenticationExt.configureTokenRefresh(auth),
      populateUser: () => AuthenticationExt.populateUser(auth),
    };

  }

  private static initialize(auth: OAuthService): Observable<AuthInitializeResponse> {

    const opts = new LoginOptions();

    return new Observable<AuthInitializeResponse>((observer) => {

      auth.loadDiscoveryDocumentAndTryLogin().then(_ => {
        if (!auth.hasValidIdToken() || !auth.hasValidAccessToken()) {
          const path = window.location.pathname;
           auth.initCodeFlow(path);
        } else {
          observer.next({ loggedIn: true, state: auth.state as string });
          observer.complete();
        }
      }, (error) => {
        const path = window.location.pathname;
         auth.initCodeFlow(path);       
      });
    });
  }

  private static populateUser(auth: OAuthService): Observable<any> {

    return new Observable<any>((observer) => {
      auth.loadUserProfile().then(data => {
        const profile = UserExtensions.parseClaims(data); 
        observer.next(profile);
        observer.complete();
      });
    });
  }
}
