<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header">
          <div class="d-flex"> 
            <h5 class="d-flex" style="flex:auto">FILE EXPORTS</h5> 
            
          </div>
          <hr/>
          <div class="row">
            <div class="col-sm-12">
                <div class="card">
                    <div class="table-responsive">
                      <ngx-datatable  class="material" #table [rows]="(exportListItem$ | async)" [limit]="10" 
                      [headerHeight]="50"
                              [footerHeight]="50" [columnMode]="ColumnMode.flex"
                              rowHeight="auto">

                              <ngx-datatable-column [flexGrow]="1" prop="processedAt" name="Date/Time">
                                <ng-template let-value="value" ngx-datatable-cell-template>
                                  {{value | date: 'dd MMMM yyyy'}}
                                </ng-template>
                              </ngx-datatable-column >
                              <ngx-datatable-column [flexGrow]="1" prop="type" name="Type"></ngx-datatable-column>
                        <ngx-datatable-column [flexGrow]="1" prop="name" name="Label"></ngx-datatable-column>
                        <!-- <ngx-datatable-column [flexGrow]="1" prop="totalRows" name="Winner Count"></ngx-datatable-column> -->
                        <!-- <ngx-datatable-column [flexGrow]="1" prop="exportedRows" name="Exported Rows"></ngx-datatable-column> -->
                        <ngx-datatable-column [flexGrow]="1" prop="configuration.entries" name="Exported Rows">
                          <ng-template let-row="row" ngx-datatable-cell-template>
                            {{ row.configuration?.entries || row.exportedRows }} <!-- Use the configuration.entries if available, otherwise use exportedRows -->
                          </ng-template>
                        </ngx-datatable-column>
               
                      
                        <ngx-datatable-column [flexGrow]="1" prop="status" name="Status"></ngx-datatable-column>
                        <ngx-datatable-column [flexGrow]="1" prop="password" name="Password"></ngx-datatable-column>
                        <ngx-datatable-column [flexGrow]="1" prop="id" name="Actions">
                          <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                            @if(row.status.toLowerCase() === 'completed'){
                              <button class="btn button-text" [ngStyle]="{'background-color': colorScheme}" (click)="downloadExport(row)">Download</button> 
                            }
                          </ng-template>
                        </ngx-datatable-column>
                      
            
                      
                      </ngx-datatable>

                    </div>
                </div>
            </div>
        </div>
        </div>
                
    </div>
  </div>
</div>
<!-- Container-fluid Ends-->

