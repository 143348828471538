import { Injectable, OnDestroy, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, map, of, Subject, Subscription, throwError } from 'rxjs';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';

import { ProjectList } from '../data/tables/project-list';
import { environment } from '../../../environments/environment';
import { getServerErrors } from '../utils/get-server-errors';
import {ProjectService} from './project.service';

@Injectable({
  providedIn: 'root'
})
export class DrawService implements OnDestroy {
  private _busy$: BehaviorSubject<boolean>;
  private _draw$: BehaviorSubject<ProjectList | null>;
  private _drawList$: BehaviorSubject<any | null>;
  private _drawsLoading$: Subject<boolean>;
  private unsubscribe = new Subject<void>()
  private _drawDownloadLoading$: Subject<boolean>;
  private _error$: Subject<string | null>;
  private _queue$: Subject<boolean>;
  private _performingDraw$: Subject<any>;

  get busy$() { return this._busy$.asObservable() }
  get draw$() {return this._draw$.asObservable()}
  get drawList$() {return this._drawList$.asObservable()}
  get drawsLoading$() {return this._drawsLoading$.asObservable()}
  get drawDownloadLoading$() {return this._drawDownloadLoading$.asObservable()}
  get error$() { return this._error$.asObservable() }
  get performingDraw$() {return this._performingDraw$.asObservable()}
  get queue$() {return this._queue$.asObservable()}

  constructor(private http: HttpClient, private projectService:ProjectService) {    
    this._busy$ = new BehaviorSubject<boolean>(false);
    this._draw$ = new BehaviorSubject<any | null>(null);
    this._drawList$ = new BehaviorSubject<any | null>(null);
    this._drawsLoading$ = new Subject<boolean>();
    this._drawDownloadLoading$ = new Subject<boolean>();
    this._error$ = new Subject<string | null>()
    this._performingDraw$ = new Subject<any>();
    this._queue$ = new Subject<boolean>();
  }


  fetchDrawTypes(token?: string): Observable<any> {
    const apiUrl = `${environment.endpoints.api}draws/types?token=${token}`;
    return this.http.get<any>(apiUrl).pipe(
      tap(data => console.log('Draw Types:', data)), 
      catchError(error => {
        console.error('Error fetching draw types:', error);
        return throwError(() => new Error('Error fetching draw types'));
      })
    );
  }

  fetchDrawTypesID(id: string, token?: string): Observable<any> {
    const apiUrl = `${environment.endpoints.api}projects/${id}/draw/types?token=${token}`;
    return this.http.get<any>(apiUrl);
}


  perform(id:string, projectId:string, token?:string){
    this._performingDraw$.next(false);
    return this.http.patch<any>(`${environment.endpoints.api}draws/${id}/perform?token=${token}`, {})
    // .pipe(
    //   takeUntil(this.unsubscribe),
      
    // )
    // .subscribe({
    //   next: (response: any) => {   
    //     this.projectService.drawList(projectId);     
    //     this._performingDraw$.next(response);
    //   },
    //   error: (err:any) => {
    //     const error: string = getServerErrors(err);
    //     this._error$.next(error);
    //   },           
    //   }
    // );
  }

  queue(id:string) {
    return this.http.patch<any>(`${environment.endpoints.api}exports/${id}/queue`, {})
    .pipe(
      takeUntil(this.unsubscribe),      
    )
    .subscribe({
      next: (response: any) => {        
        this._queue$.next(true);
      },
      error: (err:any) => {
        const error: string = getServerErrors(err);
        this._error$.next(error);
      },           
      }
    );
  }

 

  download(draw, id){
    this._drawDownloadLoading$.next(true);
    this.http.post<any>(`${environment.endpoints.api}projects/${id}/exports`, draw)
    .pipe(
      finalize(() => this._drawDownloadLoading$.next(false))
    )
    .subscribe({
        next: (response: any) => {
          let _res = JSON.stringify(response);
          const fileName = `${draw.file.name}`;
          const blob = new Blob([_res], { type: "application/json" });

            const downloadUrl = window.URL.createObjectURL(blob);
            const link: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
            link.href = downloadUrl;
  
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
  
            document.body.removeChild(link);
            window.URL.revokeObjectURL(downloadUrl);
        },
        error: (error:any) => this._error$.error(error),       
      }
    );
  }

  
  destroy(){   
    this._busy$.next(false);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this._busy$.unsubscribe();      
  }

  supdatePerform(id: string, projectId: string, isEnabled: boolean) {
    this._performingDraw$.next(false);
    
    return this.http.patch<any>(`${environment.endpoints.api}draws/${id}/perform`, { enabled: isEnabled })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (response: any) => {
          this.projectService.drawList(projectId);
          this._performingDraw$.next(response);
          console.log('Draw tool enabled status updated', response);
        },
        error: (err: any) => {
          const error: string = getServerErrors(err);
          this._error$.next(error);
        },
      });
  }
  
  updateDrawConfig(id: string, enabled: boolean) {
    const config = { enabled };  
    return this.http.put<ProjectList>(
      `${environment.endpoints.api}projects/${id}/configuration/draws`,
      config 
    );
  }
  

  
}