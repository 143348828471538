import { Component, OnInit, ViewEncapsulation, Input, ChangeDetectorRef, NgZone  } from '@angular/core';
import * as chartData from '../../../../shared/data/chart/google-chart';
import { ColorService } from '../../../../shared/services/theme.service';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-entry-status-chart',
  templateUrl: './entry-status.component.html',
  styleUrls: ['./entry-status.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EntryStatusComponent implements OnInit {
  @Input() data;
  @Input() project;
  barChart2:any;
  chartData;
  colorScheme: string;
  private _chartTheme = [];
  constructor(private colorService: ColorService, private cdr: ChangeDetectorRef, private route: ActivatedRoute ) { }

  private filterData(){  

    // this.chartData =  [
    //   ["Type", "Value", {role: "style"}],
    //   ['Valid', this.data.valid, "#4466f2"],
    //   ['Invalid',  this.data.invalid, "#1ea6ec"],
    //   ['Duplicate',  this.data.duplicate, "#22af47"],
    //   ['Other',  this.data.other, "color: #007bff"],
    //   ['Not Open',  this.data.notOpen, "color: #4466f2"],
    //   ['Closed',  this.data.closed, "#1ea6ec"],
    //   ['Not Set',  this.data.notSet, "#22af47"]
    // ]        

    this.barChart2 = {
      chartType: 'BarChart',
      dataTable: this.data,
      options: {
        title: "",
        fontName: "Roboto, 'Helvetica Neue', 'sans-serif'",
        fontSize: 14,
        width: '100%',
        height: 400,
        bar: { groupWidth: "95%" },
        legend: { position: "none" },
        backgroundColor:'transparent',
        chartArea:{left:'5%',top:'5%',width:'93%',height:'70%'},
        colors: this._chartTheme,
      },
    };
  }

  ngOnInit() {
    if(!this.data) return;
    this.setChartTheme();
  }

  private setChartTheme(){
    this.colorScheme = this.project.colorScheme;
    this._chartTheme = this.colorService.chartTheme(this.project.colorScheme);    
    this.filterData();
  }
}
