import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProjectService } from '../shared/services/project.service';
import { Router } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { SharedModule } from "../shared/shared.module";
import { NavService } from '../shared/services/nav.service';
import {SidebarComponent} from '../shared/components/sidebar/sidebar.component'
import { RightSidebarComponent} from '../shared/components/right-sidebar/right-sidebar.component'

@Component({
    selector: 'app-entry-data',
    standalone: true,
    templateUrl: './entry-data.component.html',
    styleUrl: './entry-data.component.scss',
    imports: [FormsModule, DatePipe, SharedModule]
})
export class EntryDataComponent implements OnInit {
  entryId: string;
  entryName: string;
  entryDate: string;
  entryTime: string;
  entryLastName: string;
  entryMobile: string;
  entryStatus: string;
  entryImage: string;
customizer: any;
navServices: any;
right_side_bar: any;

  constructor(private projectService: ProjectService, private router: Router,private datePipe: DatePipe, private navService: NavService) { }

  ngOnInit(): void {
    const entryData = this.projectService.getEntryData();
    if (entryData) {
      this.entryId = entryData.id;
      this.entryName = entryData.consumer.firstName;
      this.entryLastName = entryData.consumer.lastName;
      this.entryDate = entryData.createdAt;
      this.entryMobile = entryData.consumer.mobileNumber;
      this.entryStatus = entryData.moderationStatus;
      this.entryImage = entryData.payload.imageurl
      this.entryDate = this.formatDate(entryData.createdAt); 
    }
  }

  formatDate(date: string): string {
    return this.datePipe.transform(date, 'dd MMMM, yyyy, hh:mm a') || ''; // Customize the format as needed
  }

  formatTime(date: string): string {
    return this.datePipe.transform(date, 'hh:mm a') || ''; // Customize the format as needed
  }

  // formatDate(dateString: string | null): string {
  //   if (!dateString) {
  //     return 'No Date Provided'; 
  //   }
  //   const formattedDate = this.datePipe.transform(dateString, 'dd MMM, yyyy hh:mm a');
  //   return formattedDate || 'Invalid Date'; 
  // }

  cancel(): void {
    this.router.navigate(['/paper-trail']); // Adjust the route as needed
  }
}
