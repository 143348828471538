import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { OAuthService } from 'angular-oauth2-oidc';
import { Router, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { StatisticsService } from '../../../shared/services/statistics.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  private _queryParam;
  private _sharable = true;

  expired$:Observable<boolean>;

  constructor(
    private oAuth: OAuthService,
    private router: Router,
    private route: ActivatedRoute,
    private statisticsService: StatisticsService,
  ) {
    this.expired$ = this.statisticsService.expired$;
    this._queryParam = new URLSearchParams(window.location.search);
    if(this._queryParam.has('token') && !sessionStorage.getItem('publicAccess')) {
      this._sharable = true
      sessionStorage.setItem('publicAccess', 'true')
    return;
    };

//     this.expired$.subscribe(res => {  
//       console.log(res)

//       // if(!res)return; 
//       // this._nav = res;
//       // return res.name;
//     });
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const snapshot: RouterStateSnapshot = this.router.routerState.snapshot;
   // if(this._sharable) return throwError('');
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        switch (err.status) {
          case 401:
          case 403:
            
              if(this._sharable){
            //  // this.router.navigate(['/error'])
            //    return EMPTY;
                  if(err.status === 401){
                    this.expired$.subscribe((res) => {
                      console.log(res)
                      if(this._queryParam.has('token')) {
                        this._queryParam.set(null)
                        this.oAuth.initCodeFlow(snapshot.url);
                      return;
                      };
                    })
                  }
              }
            this.oAuth.initCodeFlow(snapshot.url);
            //this.oAuth.initImplicitFlow();
            return EMPTY;
          default:
            return throwError(err);
        }
      })
    );
  }
}
