// import { Injectable } from "@angular/core";
// import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
// import { Observable } from "rxjs";

// @Injectable({
//   providedIn: "root",
// })
// export class AdminGuard  {
  
//   constructor(public router: Router) {}

//   canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
//     let _projectToken = next.queryParamMap.get('token');       
//     let user = JSON.parse(localStorage.getItem('user') || '{}');

//     if (!user || user === null) {

//       this.router.navigate(['/dashboard/login']);
//       return true;
//     }
//     // else if (user) {
//     //   if (!Object.keys(user).length) {

//     //     this.router.navigate(['/auth/login']);
//     //     return true;
//     //   }
//     // }
//     return true;
//   }
// }



import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree,Router } from '@angular/router';
import { Observable } from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';
// import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard {

  private _queryParam;
  private _sharable = true;

  constructor(public router:Router,
      private authService: OAuthService) { 
        this._queryParam = new URLSearchParams(window.location.search);
        if(this._queryParam.has('token')) this._sharable = true;
      }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if(this._sharable) {
        //this.router.navigate(['/auth/login?token=1']);
        return true;
      }
      

        if (this.authService.hasValidIdToken() && !this._sharable) {
            return true;
        }else{
        }
        if(!this._sharable) {
         // this.authService.tryLoginCodeFlow()
          return false;
        }

        return false;

    }
}            
