<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div [ngStyle]="{'background-color': colorScheme}" class="daily-header" ><h6 class="m-0">Consumer Engagement</h6></div>
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr class="alig d-flex">
                                <th scope="col" style="flex:2;display:flex;justify-content: flex-start; align-items: center;"></th>
                                <th scope="col" style="flex:1;display:flex;justify-content: flex-start; align-items: center;">Target</th>
                                <th scope="col" style="flex:1;display:flex;justify-content: flex-start; align-items: center;">Achieved</th>
                                <th scope="col" style="flex:1;display:flex;justify-content: flex-start; align-items: center;">Percentage</th>                              
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="alig d-flex">
                                <th style="flex:2;display:flex;justify-content: flex-start; align-items: center;" scope="row">Unique Consumers</th>
                                <td style="flex:1;display:flex;justify-content: flex-start; align-items: center;">{{project?.targets?.recruitment ?? 0}}</td>
                                <td style="flex:1;display:flex;justify-content: flex-start; align-items: center;">{{data2.new ?? 0}}</td>
                                <td style="flex:1;display:flex;justify-content: flex-start; align-items: center;">{{(calcPercent(project?.targets?.recruitment, data2.new) | number: '1.1-2' : 'en-ZA')}}%</td>
                            </tr>
        
                            <tr class="alig d-flex">
                              <th style="flex:2;display:flex;justify-content: flex-start; align-items: center;" scope="row">Total Interactions</th>
                              <td style="flex:1;display:flex;justify-content: flex-start; align-items: center;">{{project?.targets?.engagement ?? 0}}</td>                              
                              @if(data2 && data2.new){
                                <td style="flex:1;display:flex;justify-content: flex-start; align-items: center;">{{data2.new + data2.returning}}</td>
                              }@else {
                                <td style="flex:1;display:flex;justify-content: flex-start; align-items: center;">0</td>
                              }
                              <td style="flex:1;display:flex;justify-content: flex-start; align-items: center;">{{(calcPercent(project?.targets?.engagement, (data2.new + data2.returning)) | number: '1.1-2' : 'en-ZA')}}%</td>
                          </tr>   

                          <!-- <tr class="alig d-flex">
                            <th style="flex:2;display:flex;justify-content: flex-start; align-items: center;" scope="row">Unique Consumers</th>
                            <td style="flex:1;display:flex;justify-content: flex-start; align-items: center;">{{project?.targets?.recruitment ?? 0}}</td>
                            <td style="flex:1;display:flex;justify-content: flex-start; align-items: center;">{{data2.new ?? 0}}</td>
                            <td style="flex:1;display:flex;justify-content: flex-start; align-items: center;">{{(calcPercent(project?.targets?.recruitment, data2.new) | number: '1.1-2' : 'en-ZA')}}%</td>
                        </tr> -->
                        </tbody>
                    </table>
                </div>              
            </div>
        </div>
    </div>    
</div>