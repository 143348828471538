export const environment = {
  production: true,
  storeKey: 'test',
  clientUrl: 'https://easydash.techsys.co.za',
  endpoints: {
    authority: 'https://signin.techsys.co.za/realms/techsys',
    identity: 'https://signin.techsys.co.za/realms/techsys',
    api: 'https://easydash.techsys.co.za/api/',
  },
  authentication: {
    appId: 'ez-dash',   
    scopes: 'openid profile email brands.read brands.write consumers.read consumers.write draws.read draws.write entries.read entries.write integrations.read integrations.write projects.read projects.write offline_access',
    appSecret: 'Sg5YoB2T8YHUpBOvBeKeVq8x90QwAki6',
    showDebug: false,
    enableSessionChecks: true,
    requireHttps: false,
    silentRefreshShowIFrame: false,
    skipIssuerCheck: true
  }
};
