import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import * as chartData from '../../../../shared/data/chart/google-chart';
import { ColorService } from '../../../../shared/services/theme.service';
import { ActivatedRoute } from '@angular/router';
import { format } from 'date-fns';
interface IStatusDaily{
  sampledAt:string;
  valid:number;
  invalid:number;
  duplicate:number;
  other:number;
  notOpen:number;
  closed:number;
  notSet:number;
}

@Component({
  selector: 'app-entry-status-daily-chart',
  templateUrl: './entry-status-daily.component.html',
  styleUrls: ['./entry-status-daily.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EntryStatusDailyComponent implements OnInit {
  @Input() data;
  @Input() project;
  areaChart2: any;
  colorScheme: string;
  private _chartTheme = [];
  chartData;
  constructor(private colorService: ColorService, private route: ActivatedRoute) { }


  ngOnInit() {
    if(!this.data) return;
    this.setChartTheme();
  }

  private setChartTheme(){
    this.colorScheme = this.project.colorScheme;
    this._chartTheme = this.colorService.chartTheme(this.project.colorScheme);    
    this.filterData();
  }



  private filterData() {
    this.areaChart2 = {
      chartType: 'LineChart',
      dataTable: this.data,
      options: {
        chart: {
          title: '',
          subtitle: ''
        },
        title: '',
        curveType: 'function',
        lineWidth:4,
        fontName: "Roboto, 'Helvetica Neue', 'sans-serif'",
        fontSize: 13,
        // hAxis: { title: 'Entries', titleTextStyle: { color: '#333' }, format: 'short' },
        vAxis: { minValue: 0 , format: 'short' },
        width: '199%',
        legend: { position: "bottom" },
        height: 400,
        colors: this._chartTheme,
        fullWidth: true,    
        backgroundColor:'transparent',
        // curveType: 'function',
        chartArea:{left:'4.8%',top:'5%',width:'93%',height:'70%'}

      
      },
    };
  }


}
