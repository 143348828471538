import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, map, of, Subject, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { ProjectList } from '../data/tables/project-list';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StatisticsService implements OnInit, OnDestroy {
//private _token = 'OGNlZmZmOWE1YjI3NDNjZGE4MGEzMDAwOTc2YjM4Y2Y=';
  ngOnInit(): void {
      
  }

  private _entriesByDayLoading$: BehaviorSubject<boolean>;
  private _entriesByHourLoading$: BehaviorSubject<boolean>;
  private _dataEnrichmentByDayLoading$: BehaviorSubject<boolean>;
  private _dataEnrichmentSummaryLoading$: BehaviorSubject<boolean>;
  private _recruitmentByDayLoading$: BehaviorSubject<boolean>;
  private _recruitmentSummaryLoading$: BehaviorSubject<boolean>;
  private _rewardsByDayLoading$: BehaviorSubject<boolean>;
  private _rewardsSummaryLoading$: BehaviorSubject<boolean>;
  private _statusByDayLoading$: BehaviorSubject<boolean>;
  private _statusSummaryLoading$: BehaviorSubject<boolean>;
  private _subscriptionStatusByDayLoading$: BehaviorSubject<boolean>;
  private _subscriptionStatusSummaryLoading$: BehaviorSubject<boolean>;
  private _typeByDayLoading$: BehaviorSubject<boolean>;
  private _typeSummaryLoading$: BehaviorSubject<boolean>;

  private _entriesByDay$: BehaviorSubject<any>;
  private _entriesByHour$: BehaviorSubject<any>;
  private _dataEnrichmentByDay$: BehaviorSubject<any>;
  private _dataEnrichmentSummary$: BehaviorSubject<any>;
  private _recruitmentByDay$: BehaviorSubject<any>;
  private _recruitmentSummary$: BehaviorSubject<any>;
  private _rewardsByDay$: BehaviorSubject<any>;
  private _rewardsSummary$: BehaviorSubject<any>;
  private _statusByDay$: BehaviorSubject<any>;
  private _statusSummary$: BehaviorSubject<any>;
  private _subscriptionStatusByDay$: BehaviorSubject<any>;
  private _subscriptionStatusSummary$: BehaviorSubject<any>;
  private _typeByDay$: BehaviorSubject<any>;
  private _typeSummary$: BehaviorSubject<any>;
  private _expired$: BehaviorSubject<any>;

  get entriesByDay$() { return this._entriesByDay$.asObservable() } 
  get entriesByHour$() { return this._entriesByHour$.asObservable() } 
  get dataEnrichmentByDay$() { return this._dataEnrichmentByDay$.asObservable() } 
  get dataEnrichmentSummary$() { return this._dataEnrichmentSummary$.asObservable() }  
  get recruitmentByDay$() { return this._recruitmentByDay$.asObservable() }  
  get recruitmentSummary$() { return this._recruitmentSummary$.asObservable() }  
  get rewardsByDay$() { return this._rewardsByDay$.asObservable() }  
  get rewardsSummary$() { return this._rewardsSummary$.asObservable() }  
  get statusByDay$() { return this._statusByDay$.asObservable() }
  get statusSummary$() { return this._statusSummary$.asObservable() }  
  get subscriptionStatusByDay$() { return this._subscriptionStatusByDay$.asObservable() }  
  get subscriptionStatusSummary$() { return this._subscriptionStatusSummary$.asObservable() }  
  get typeByDay$() { return this._typeByDay$.asObservable() }  
  get typeSummary$() { return this._typeSummary$.asObservable() }
  get expired$(){return this._expired$.asObservable()}

  get entriesByDayLoading$() { return this._entriesByDayLoading$.asObservable() }
  get entriesByHourLoading$() { return this._entriesByHourLoading$.asObservable() }
  get dataEnrichmentByDayLoading$() { return this._dataEnrichmentByDayLoading$.asObservable() }
  get dataEnrichmentSummaryLoading$() { return this._dataEnrichmentSummaryLoading$.asObservable() }
  get recruitmentByDayLoading$() { return this._recruitmentByDayLoading$.asObservable() }
  get recruitmentSummaryLoading$() { return this._recruitmentSummaryLoading$.asObservable() }
  get rewardsByDayLoading$() { return this._rewardsByDayLoading$.asObservable() }
  get rewardsSummaryLoading$() { return this._rewardsSummaryLoading$.asObservable() }
  get statusByDayLoading$() { return this._statusByDayLoading$.asObservable() }
  get statusSummaryLoading$() { return this._statusSummaryLoading$.asObservable() }
  get subscriptionStatusByDayLoading$() { return this._subscriptionStatusByDayLoading$.asObservable() }
  get subscriptionStatusSummaryLoading$() { return this._subscriptionStatusSummaryLoading$.asObservable() }
  get typeByDayLoading$() { return this._typeByDayLoading$.asObservable() }
  get typeSummaryLoading$() { return this._typeSummaryLoading$.asObservable() }

  constructor(private http: HttpClient) {    
    this._entriesByDay$ = new BehaviorSubject<any>(null);
    this._entriesByHour$ = new BehaviorSubject<any>(null);
    this._dataEnrichmentByDay$ = new BehaviorSubject<any>(null);
    this._dataEnrichmentSummary$ = new BehaviorSubject<any>(null);
    this._recruitmentByDay$ = new BehaviorSubject<any>(null);
    this._recruitmentSummary$ = new BehaviorSubject<any>(null);
    this._rewardsByDay$ = new BehaviorSubject<any>(null);
    this._rewardsSummary$ = new BehaviorSubject<any>(null);
    this._statusByDay$ = new BehaviorSubject<any>(null);
    this._statusSummary$ = new BehaviorSubject<any>(null);
    this._subscriptionStatusByDay$ = new BehaviorSubject<any>(null);
    this._subscriptionStatusSummary$ = new BehaviorSubject<any>(null);
    this._typeByDay$ = new BehaviorSubject<any>(null);
    this._typeSummary$ = new BehaviorSubject<any>(null);

    this._entriesByDayLoading$ = new BehaviorSubject<any>(null);
    this._entriesByHourLoading$ = new BehaviorSubject<any>(null);
    this._dataEnrichmentByDayLoading$ = new BehaviorSubject<any>(null);
    this._dataEnrichmentSummaryLoading$ = new BehaviorSubject<any>(null);
    this._recruitmentByDayLoading$ = new BehaviorSubject<any>(null);
    this._recruitmentSummaryLoading$ = new BehaviorSubject<any>(null);
    this._rewardsByDayLoading$ = new BehaviorSubject<any>(null);
    this._rewardsSummaryLoading$ = new BehaviorSubject<any>(null);
    this._statusByDayLoading$ = new BehaviorSubject<any>(null);
    this._statusSummaryLoading$ = new BehaviorSubject<any>(null);
    this._subscriptionStatusByDayLoading$ = new BehaviorSubject<any>(null);
    this._subscriptionStatusSummaryLoading$ = new BehaviorSubject<any>(null);
    this._typeByDayLoading$ = new BehaviorSubject<any>(null);
    this._typeSummaryLoading$ = new BehaviorSubject<any>(null);

    this._expired$ = new BehaviorSubject<boolean>(true);
  }

  ngOnDestroy(): void {
    this._entriesByDay$.unsubscribe();
    this._entriesByHour$.unsubscribe();
    this._dataEnrichmentByDay$.unsubscribe();
    this._dataEnrichmentSummary$.unsubscribe();
    this._recruitmentByDay$.unsubscribe();
    this._recruitmentSummary$.unsubscribe();
    this._rewardsByDay$.unsubscribe();
    this._rewardsSummary$.unsubscribe();
    this._statusByDay$.unsubscribe();
    this._statusSummary$.unsubscribe();
    this._subscriptionStatusByDay$.unsubscribe();
    this._subscriptionStatusSummary$.unsubscribe();
    this._typeByDay$.unsubscribe();
    this._typeSummary$.unsubscribe();

    this._entriesByDayLoading$.unsubscribe();
    this._entriesByHourLoading$.unsubscribe();
    this._dataEnrichmentByDayLoading$.unsubscribe();
    this._dataEnrichmentSummaryLoading$.unsubscribe();
    this._recruitmentByDayLoading$.unsubscribe();
    this._recruitmentSummaryLoading$.unsubscribe();
    this._rewardsByDayLoading$.unsubscribe();
    this._rewardsSummaryLoading$.unsubscribe();
    this._statusByDayLoading$.unsubscribe();
    this._statusSummaryLoading$.unsubscribe();
    this._subscriptionStatusByDayLoading$.unsubscribe();
    this._subscriptionStatusSummaryLoading$.unsubscribe();
    this._typeByDayLoading$.unsubscribe();
    this._typeSummaryLoading$.unsubscribe();

    this._expired$.unsubscribe();
   
  }

  destroy(): void {
    this._entriesByDay$.next(null);
    this._entriesByHour$.next(null);
    this._dataEnrichmentByDay$.next(null);
    this._dataEnrichmentSummary$.next(null);
    this._recruitmentByDay$.next(null);
    this._recruitmentSummary$.next(null);
    this._rewardsByDay$.next(null);
    this._rewardsSummary$.next(null);
    this._statusByDay$.next(null);
    this._statusSummary$.next(null);
    this._subscriptionStatusByDay$.next(null);
    this._subscriptionStatusSummary$.next(null);
    this._typeByDay$.next(null);
    this._typeSummary$.next(null);

    this._entriesByDayLoading$.next(true);
    this._entriesByHourLoading$.next(true);
    this._dataEnrichmentByDayLoading$.next(true);
    this._dataEnrichmentSummaryLoading$.next(true);
    this._recruitmentByDayLoading$.next(true);
    this._recruitmentSummaryLoading$.next(true);
    this._rewardsByDayLoading$.next(true);
    this._rewardsSummaryLoading$.next(true);
    this._statusByDayLoading$.next(true);
    this._statusSummaryLoading$.next(true);
    this._subscriptionStatusByDayLoading$.next(true);
    this._subscriptionStatusSummaryLoading$.next(true);
    this._typeByDayLoading$.next(true);
    this._typeSummaryLoading$.next(true);
   
  }

  fetchEntriesByDay(id:string, renderChart:boolean, token?:string) {
    this._entriesByDayLoading$.next(true);
    // this._expired$.next(false)
    return this.http.get<any>(`${environment.endpoints.api}projects/${id}/statistics/entries/by-day?renderChart=${renderChart}&token=${token}`)
    .pipe(
      finalize(() => this._entriesByDayLoading$.next(false))
    )
    .subscribe({
        next: (response: any) => {
          this._expired$.next(false)
          this._entriesByDay$.next(response)
        },
        error: (error:any) => {
          this._expired$.next(true)
          this._entriesByDay$.next(error)
        },
      }
    );
  }

  fetchEntriesByHour(id:string, renderChart:boolean, token?:string) {
    this._entriesByHourLoading$.next(true);
    return this.http.get<ProjectList>(`${environment.endpoints.api}projects/${id}/statistics/entries/by-hour-of-day?renderChart=${renderChart}&token=${token}`)
    .pipe(
      finalize(() => this._entriesByHourLoading$.next(false))
    )
    .subscribe({
        next: (response: any) => this._entriesByHour$.next(response),
        error: (error:any) => this._entriesByHour$.error(error),
      }
    );
  }

  fetchDataEnrichmentByDay(id:string, renderChart:boolean, token?:string) {
    this._dataEnrichmentByDayLoading$.next(true);
    return this.http.get<ProjectList>(`${environment.endpoints.api}projects/${id}/statistics/data-enrichment/by-day?renderChart=${renderChart}&token=${token}`)
    .pipe(
      finalize(() => this._dataEnrichmentByDayLoading$.next(false))
    )
    .subscribe({
        next: (response: any) => this._dataEnrichmentByDay$.next(response),
        error: (error:any) => this._dataEnrichmentByDay$.error(error),
      }
    );
  }

  fetchDataEnrichmentSummary(id:string, renderChart:boolean, token?:string) {
    this._dataEnrichmentSummaryLoading$.next(true);
    return this.http.get<ProjectList>(`${environment.endpoints.api}projects/${id}/statistics/data-enrichment/summary?renderChart=${renderChart}&token=${token}`)
    .pipe(
      finalize(() => this._dataEnrichmentSummaryLoading$.next(false))
    )
    .subscribe({
        next: (response: any) => this._dataEnrichmentSummary$.next(response),
        error: (error:any) => this._dataEnrichmentSummary$.error(error),
      }
    );
  }

  fetchRecruitmentByDay(id:string, renderChart:boolean, token?:string) {
    this._recruitmentByDayLoading$.next(true);
    return this.http.get<ProjectList>(`${environment.endpoints.api}projects/${id}/statistics/recruitment/by-day?renderChart=${renderChart}&token=${token}`)
    .pipe(
      finalize(() => this._recruitmentByDayLoading$.next(false))
    )
    .subscribe({
        next: (response: any) => this._recruitmentByDay$.next(response),
        error: (error:any) => this._recruitmentByDay$.error(error),
      }
    );
  }

  fetchRecruitmentSummary(id:string, renderChart:boolean, token?:string) {
    this._recruitmentSummaryLoading$.next(true);
    return this.http.get<ProjectList>(`${environment.endpoints.api}projects/${id}/statistics/recruitment/summary?renderChart=${renderChart}&token=${token}`)
    .pipe(
      finalize(() => this._recruitmentSummaryLoading$.next(false))
    )
    .subscribe({
        next: (response: any) => this._recruitmentSummary$.next(response),
        // error: (error:any) => this._recruitmentSummary$.error(error),
        error: (error:any) => this._recruitmentSummary$.next([]),
      }
    );
  }

  fetchRewardsByDay(id:string, renderChart:boolean, token?:string) {
    this._rewardsByDayLoading$.next(true);
    return this.http.get<ProjectList>(`${environment.endpoints.api}projects/${id}/statistics/rewards/by-day?renderChart=${renderChart}&token=${token}`)
    .pipe(
      finalize(() => this._rewardsByDayLoading$.next(false))
    )
    .subscribe({
        next: (response: any) => this._rewardsByDay$.next(response),
        error: (error:any) => this._rewardsByDay$.error(error),
      }
    );
  }

  fetchRewardsSummary(id:string, renderChart:boolean, token?:string) {
    this._rewardsSummaryLoading$.next(true);
    return this.http.get<ProjectList>(`${environment.endpoints.api}projects/${id}/statistics/rewards/summary?renderChart=${renderChart}&token=${token}`)
    .pipe(
      finalize(() => this._rewardsSummaryLoading$.next(false))
    )
    .subscribe({
        next: (response: any) => this._rewardsSummary$.next(response),
        error: (error:any) => this._rewardsSummary$.error(error),
      }
    );
  }

  fetchStatusByDay(id:string, renderChart:boolean, token?:string) {
    this._statusByDayLoading$.next(true);
    return this.http.get<ProjectList>(`${environment.endpoints.api}projects/${id}/statistics/status/by-day?renderChart=${renderChart}&token=${token}`)
    .pipe(
      finalize(() => this._statusByDayLoading$.next(false))
    )
    .subscribe({
        next: (response: any) => this._statusByDay$.next(response),
        error: (error:any) => this._statusByDay$.error(error),
      }
    );
  }

  fetchStatusSummary(id:string, renderChart:boolean, token?:string) {
    this._statusSummaryLoading$.next(true);
    return this.http.get<ProjectList>(`${environment.endpoints.api}projects/${id}/statistics/status/summary?renderChart=${renderChart}&token=${token}`)
    .pipe(
      finalize(() => this._statusSummaryLoading$.next(false))
    )
    .subscribe({
        next: (response: any) => this._statusSummary$.next(response),
        error: (error:any) => this._statusSummary$.error(error),
      }
    );
  }

  fetchSubscriptionStatusByDay(id:string, renderChart:boolean, token?:string) {
    this._subscriptionStatusByDayLoading$.next(true);
    return this.http.get<ProjectList>(`${environment.endpoints.api}projects/${id}/statistics/subscription-status/by-day?renderChart=${renderChart}&token=${token}`)
    .pipe(
      finalize(() => this._subscriptionStatusByDayLoading$.next(false))
    )
    .subscribe({
        next: (response: any) => this._subscriptionStatusByDay$.next(response),
        error: (error:any) => this._subscriptionStatusByDay$.error(error),
      }
    );
  }

  fetchSubscriptionStatusSummary(id:string, renderChart:boolean, token?:string) {
    this._subscriptionStatusSummaryLoading$.next(true);
    return this.http.get<ProjectList>(`${environment.endpoints.api}projects/${id}/statistics/subscription-status/summary?renderChart=${renderChart}&token=${token}`)
    .pipe(
      finalize(() => this._subscriptionStatusSummaryLoading$.next(false))
    )
    .subscribe({
        next: (response: any) => {
          this._subscriptionStatusSummary$.next(response);

        },
        error: (error:any) => this._subscriptionStatusSummary$.error(error),
      }
    );
  }

  fetchTypeByDay(id:string, renderChart:boolean, token?:string) {
    this._typeByDayLoading$.next(true);
    return this.http.get<ProjectList>(`${environment.endpoints.api}projects/${id}/statistics/subscription-status/by-day?renderChart=${renderChart}&token=${token}`)
    .pipe(
      finalize(() => this._typeByDayLoading$.next(false))
    )
    .subscribe({
        next: (response: any) => this._typeByDay$.next(response),
        error: (error:any) => this._typeByDay$.error(error),
      }
    );
  }

  fetchTypeSummary(id:string, renderChart:boolean, token?:string) {
    this._typeSummaryLoading$.next(true);
    return this.http.get<ProjectList>(`${environment.endpoints.api}projects/${id}/statistics/subscription-status/summary?renderChart=${renderChart}&token=${token}`)
    .pipe(
      finalize(() => this._typeSummaryLoading$.next(false))
    )
    .subscribe({
        next: (response: any) => this._typeSummary$.next(response),
        error: (error:any) => this._typeSummary$.error(error),
      }
    );
  }
}