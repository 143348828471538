<div class="container-fluid">
  <div class="row">
      <div class="col-sm-12">
          <div class="card">
            <div [ngStyle]="{'background-color': colorScheme}" class="daily-header" ><h6 class="m-0">Consumer Enrichment</h6></div>
              <div class="table-responsive"> 
                  <table class="table">
                      <thead>
                          <tr class="alig d-flex"> 
                              <th scope="col" style="flex:2;display:flex;justify-content: flex-start; align-items: center;"></th>
                              <th style="flex:1;display:flex;justify-content: flex-start; align-items: center;">Target</th>
                              <th scope="col" style="flex:1;display:flex;justify-content: flex-start; align-items: center;">Achieved</th>
                              <th scope="col" style="flex:1;display:flex;justify-content: flex-start; align-items: center;">Percentage</th>                              
                          </tr>
                      </thead>
                      <tbody>
                        @for(stat of data; track stat){
                          <tr class="alig d-flex">
                            <th style="flex:2;display:flex;justify-content: flex-start; align-items: center;">{{stat.property}}</th>
                            <td style="flex:1;display:flex;justify-content: flex-start; align-items: center;">{{data2.new ?? 0}}</td>

                            <td style="flex:1;display:flex;justify-content: flex-start; align-items: center;">{{stat.created}}</td>
                            <td style="flex:1;display:flex;justify-content: flex-start; align-items: center;">{{stat?.created && data2?.new ? (calcPercent(data2?.new, stat?.created) | number: '1.1-2' : 'en-ZA') : '0'}}%</td>      
                          </tr>                 
                        }@empty {
                          <h4 class="text-center p-t-25 p-b-25">No Available Data Found.</h4>                          
                        }         
                      </tbody>
                  </table>  
              </div>
          </div>
      </div>
  </div>
</div>