import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';

@Component({
  selector: 'app-standard-message-draw-tool',
  standalone: true,
  imports: [], // make sure to import necessary Material modules here if needed
  templateUrl: './standard-message-draw-tool.component.html',
  styleUrls: ['./standard-message-draw-tool.component.scss']
})
export class StandardMessageDrawToolComponent {
  constructor(
    public dialogRef: MatDialogRef<StandardMessageDrawToolComponent>, // Inject MatDialogRef
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  close() {
    this.dialogRef.close();
  }
}
