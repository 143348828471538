import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import * as chartData from '../../../../shared/data/chart/google-chart';
import { BehaviorSubject, Observable } from 'rxjs';
import { ColorService } from '../../../../shared/services/theme.service';
import { ActivatedRoute } from '@angular/router'
@Component({
  selector: 'app-daily-chart',
  templateUrl: './daily-chart.component.html',
  styleUrls: ['./daily-chart.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DailyChartComponent implements OnInit {
  @Input() data;
  @Input() project;
  @Input() loading;
  
  lineChart:any;
  chartData;
 colorScheme: string;

 private _chartTheme = [];
  constructor(private colorService: ColorService,  private route: ActivatedRoute) { }

  private filterData(){

    if(this.data.length <= 1)this.chartData = [];
    this.chartData = this.data;
   
    this.lineChart = {
      chartType: 'LineChart',
      dataTable: this.chartData,
      options: {
        chart: {         
          title: '',
          subtitle: '',
        },
        title: '',
        curveType: 'function',
        lineWidth:4,
        fontName: "Roboto, 'Helvetica Neue', 'sans-serif'",
        fontSize: 13,
        // hAxis: { title: 'Entries', titleTextStyle: { color: '#333' }, format: 'short' },
        vAxis: { minValue: 0 , format: 'short' },
        subtitle: 'in millions of dollars (USD)',
        colors: this._chartTheme,
        fullWidth: true,    
        height: 360,
        backgroundColor:'transparent',
        legend: { position: 'bottom' },
        chartArea:{left:'4.8%',top:'5%',width:'93%',height:'70%'},  
        
      },
    };
  }


  ngOnInit() {
    if(!this.data) return;
    this.setChartTheme();
  }

  private setChartTheme(){
    this.colorScheme = this.project.colorScheme;
    this._chartTheme = this.colorService.chartTheme(this.project.colorScheme);    
    this.filterData();
  }
}
