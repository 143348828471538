
<!-- entry-data.component.html -->
<div class="container">
  <div class="header">
    <h2>Raw Data for {{ entryName }}</h2>
  </div>
  <form class="form">
    <div class="form-group">
      <label for="firstName">First Name</label>
      <input type="text" id="firstName" [(ngModel)]="entryName" name="firstName" class="form-control" disabled />
    </div>
    
    <div class="form-group">
      <label for="lastName">Last Name</label>
      <input type="text" id="lastName" [(ngModel)]="entryLastName" name="lastName" class="form-control" disabled />
    </div>

    <div class="form-group">
      <label for="date">Date</label>
      <div class="date-time">
        <div class="date">{{ entryDate }}</div>
        <div class="small-time">{{ entryTime }}</div>
      </div>
    </div>

    <div class="form-group">
      <label for="mobile">Mobile Number</label>
      <input type="text" id="mobile" [(ngModel)]="entryMobile" name="mobile" class="form-control" disabled />
    </div>

    <div class="form-group">
      <label for="status">Status</label>
      <input type="text" id="status" [(ngModel)]="entryStatus" name="status" class="form-control" disabled />
    </div>

    <div class="form-group">
      <label for="image">Image</label>
      <img [src]="entryImage" alt="Entry Image" class="img-thumbnail" />
    </div>

    <button type="button" class="btn btn-cancel" (click)="cancel()">Cancel</button>
  </form>
</div>
