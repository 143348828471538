import { Injectable,Injector, OnInit } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';
import { switchMap } from 'rxjs/operators';
import { Router, RouterStateSnapshot, ActivatedRoute } from '@angular/router';

// @Injectable()
// export class TokenInterceptor implements HttpInterceptor {

//   constructor(private oAuth: OAuthService) {}

//   intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
//     return of(this.oAuth.getAccessToken()).pipe(
//       switchMap((token) => {
//         request = request.clone({
//           setHeaders: {
//             Authorization: `Bearer ${token}`,
//           }
//         });
//         return next.handle(request);
//       })
//     );
//   }
// }

@Injectable()
export class TokenInterceptor implements HttpInterceptor, OnInit {
  private _queryParam;
  private _sharable = false;

  constructor(private oAuth: OAuthService, private route :ActivatedRoute, private router:Router) {
    // this._queryParam = new URLSearchParams(window.location.search);
    // if(this._queryParam.has('token')) this._sharable = true;
  }

  ngOnInit(): void {
    this._queryParam = new URLSearchParams(window.location.search);
    if(this._queryParam.has('token')) this._sharable = true;
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): any{
   const searchParams = new URLSearchParams(window.location.search);

      if(this._sharable){
        this.router.navigate([window.location.pathname],{ queryParams: { token: searchParams.get('token') } })
        return next.handle(request);
      }else{
      if(!this.oAuth.getAccessToken()){
        return next.handle(request);
      }
      return of(this.oAuth.getAccessToken()).pipe(
        switchMap((token) => {
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`,
            }
          });
          return next.handle(request);
        })
      );
    }
  }
}
