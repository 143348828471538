import { Injector } from '@angular/core';
import {Router, Route,ActivatedRoute} from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc/oauth-service';
import { AuthenticationExt } from '../../extensions/authentication-extensions';

export function OauthServiceInitializer(provider: OAuthService, injector: Injector) {
  let _sharable = false;
  const _queryParam = new URLSearchParams(window.location.search);
  if(_queryParam.has('token')) _sharable = true;

  return () => {

    if(_sharable){
      const searchParams = new URLSearchParams(window.location.search);
      return new Promise((resolve, reject) => {
        const router = injector.get(Router);      
        router.navigate([window.location.pathname],{ queryParams: { token: searchParams.get('token') } })
        resolve(true);
      })
    }
    else{
      const oauth = AuthenticationExt.Configure(provider);
      return new Promise((resolve, reject) => {
        oauth.initialize()
          .subscribe((response) => {
            if (!response.loggedIn) {
              reject('user is not logged in');
            }
            if (response.state) {
              const router = injector.get(Router);
              router.navigateByUrl(decodeURIComponent(response.state));
            } 
            oauth.populateUser()
              .subscribe((user) => {
                resolve(true);
              });
          });
      });
    };
    }
}
  

//}
