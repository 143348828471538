<!-- StandardMessageDrawToolComponent.html -->
<div class="dialog-box">
    <h1>Export Started</h1>
    <p>Your export will complete shortly.<br>
       Once it is done, it will appear in the “exports tab”.</p>
    <div class="dialog-actions">
      <!-- <button class="btn cancel" (click)="close()">No</button> -->
      <button class="btn confirm" (click)="close()">Close</button>
    </div>
  </div>
  