import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { LoaderComponent } from "./components/loader/loader.component";
import { HeaderComponent } from "./components/header/header.component";
import { FooterComponent } from "./components/footer/footer.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { ContentLayoutComponent } from "./components/layout/content-layout/content-layout.component";
import { FullLayoutComponent } from "./components/layout/full-layout/full-layout.component";
import { FeatherIconsComponent } from "./components/feather-icons/feather-icons.component";
import { BreadcrumbComponent } from "./components/breadcrumb/breadcrumb.component";
import { RightSidebarComponent } from "./components/right-sidebar/right-sidebar.component";
import { BookmarkComponent } from "./components/bookmark/bookmark.component";
import { TranslateModule } from "@ngx-translate/core";
import { AvatarModule } from 'ngx-avatars';
//import { CustomizerComponent } from "./components/customizer/customizer.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
// import { GalleryModule } from "@ks89/angular-modal-gallery";

import { NoResultComponent } from './components/no-results/no-results.component';
import { ChartLoaderComponent } from './components/chart-loader/chart-loader.component';

// services
import { NavService } from "./services/nav.service";
import { ChatService } from "./services/chat.service";
import { CustomizerService } from "./services/customizer.service";
import { TableService } from "./services/table.service";
// Directives
import { ToggleFullscreenDirective } from "./directives/fullscreen.directive";
import { NgbdSortableHeader } from "./directives/NgbdSortableHeader";
import { HttpClient } from "@angular/common/http";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

//Project Setup
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { ClipboardModule } from 'ngx-clipboard';
import { UploadWidgetModule } from "@bytescale/upload-widget-angular";

 import {DrawToolModule} from '../components/draw-tool/draw-tool.module';
 import { HttpClientModule } from '@angular/common/http';
 import { MatProgressBarModule } from '@angular/material/progress-bar';


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    LoaderComponent, 
    HeaderComponent, 
    FooterComponent, 
    SidebarComponent, 
    BookmarkComponent, 
    RightSidebarComponent, 
    ContentLayoutComponent, 
    FullLayoutComponent, 
    FeatherIconsComponent, 
    ToggleFullscreenDirective, 
    NgbdSortableHeader, 
    BreadcrumbComponent, 
    //CustomizerComponent, 
    NoResultComponent,
    ChartLoaderComponent,

  ],
  imports: [
    HttpClientModule,
    CommonModule, 
    RouterModule, 
    FormsModule, 
    TranslateModule, 
    NgbModule, 
    // GalleryModule, 
    NgxDatatableModule, 
    ReactiveFormsModule,
    ClipboardModule,
    UploadWidgetModule,
    AvatarModule
   //DrawToolModule
  ],
  exports: [
    MatProgressBarModule,
    LoaderComponent, 
    AvatarModule,
    FeatherIconsComponent, 
    TranslateModule, 
    NgbdSortableHeader, 
    NgxDatatableModule, 
    NoResultComponent,
    ClipboardModule,
    ChartLoaderComponent,
    BreadcrumbComponent,
    // DrawToolModule
  ],
  providers: [
    NavService, 
    ChatService, 
    CustomizerService, 
    TableService, 
    NgbModule, 
    NgxDatatableModule,
    UploadWidgetModule,
    // DrawToolModule
  ],
})
export class SharedModule { }



