import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import * as chartData from '../../../../shared/data/chart/google-chart';
import { ProjectService } from '../../../../shared/services/project.service';
import { ColorService } from '../../../../shared/services/theme.service';
import { ActivatedRoute } from '@angular/router'

@Component({
  selector: 'app-enrichment-targets',
  templateUrl: './enrichment-targets.component.html',
  styleUrls: ['./enrichment-targets.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EnrichmentTargetsComponent implements OnInit {
  @Input() data;
  @Input() data2;
  @Input() data3;
  @Input() project;

  colorScheme: string;

  constructor(private projectService: ProjectService,private colorService: ColorService,private route: ActivatedRoute) { }

  ngOnInit() {
    if(!this.data) return;

    // this.projectService.currentProject.subscribe(project => {
    //   if (project) {
    //     this.project = project;
    //     debugger
    //     this.getProjectDetails(this.project.id);
    //   }
    // });
    this.colorScheme = this.project.colorScheme;
  }
 

  calcPercent(target,value){ return (value / target) * 100 }
}
