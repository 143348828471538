import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { barChartSingle, pieChart, multiData, single } from '../../../../shared/data/chart/ngx-chart';
import * as graphoptions from '../../../../shared/data/chart/config';
import { ColorService } from '../../../../shared/services/theme.service';
import { Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router'; 
@Component({
  selector: 'app-summary-chart',
  templateUrl: './summary-chart.component.html',
  styleUrls: ['./summary-chart.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SummaryChartComponent implements OnInit {
  @Input() data;
  @Input() data2;
  @Input() project;
  colorScheme: string;
  private unsubscribe = new Subject<void>();
  projectId: string;

  // public barChartsingle = barChartSingle;
  // public pieChart = pieChart;
  // public multiData = multiData;
  // public single = single

  constructor(private colorService: ColorService, private route: ActivatedRoute,) {
    //Object.assign(this, { multiData, barChartSingle, pieChart, single })
  }

  ngOnInit() {
    this.colorScheme = this.project.colorScheme;
    
  }
  
  // getProjectDetails(id: string): void {
  //   this.colorService.getProjectById(id).subscribe(
  //     (projectDetails) => {
  //       console.log('Project details:', projectDetails);
  //       this.colorScheme = projectDetails.colorScheme;
  //     },
  //     (error) => {
  //       console.error('Error fetching project details:', error);
  //     }
  //   );
  // }


 
  // ngOnDestroy(): void {
  //   this.unsubscribe.next();
  //   this.unsubscribe.complete();
  
  // }
  
  calculateAvg(){
    let _vals = (this.data?.new + this.data?.returning) / this.data?.new;
    if(isNaN(_vals)) return 0;
    return (this.data?.new + this.data?.returning) / this.data?.new;    
  }

  calcOptIns(data) {return data[1]?.optedIn ?? 0}


}
