import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import * as chartData from '../../../../shared/data/chart/google-chart';
import { ColorService } from '../../../../shared/services/theme.service';
import { ActivatedRoute } from '@angular/router'
const COLOUR = ["#4466f2","#1ea6ec","#22af47"];

@Component({
  selector: 'app-prize-types-chart',
  templateUrl: './prize-types.component.html',
  styleUrls: ['./prize-types.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PrizeTypesComponent implements OnInit {
  @Input() data;
  @Input() project;
  barChart2:any;
  chartData;
  private _chartTheme = [];
  colorScheme: string;
  constructor(private colorService: ColorService, private route: ActivatedRoute) { }

  private filterData(){  
 

    this.barChart2 = {
      chartType: 'BarChart',
      dataTable: this.data,
      options: {
        //fontName:'Roboto',
        title: "",
        width: '100%',
        height: 450,
        fontName: "Roboto, 'Helvetica Neue', 'sans-serif'",
        fontSize: 14,
        bar: { groupWidth: "95%" },
        legend: { position: "none" },
        backgroundColor:'transparent',
        colors: this._chartTheme,
      },
    };
  }
  ngOnInit() {
    if(!this.data) return;
    this.setChartTheme();
  }

  private setChartTheme(){
    this.colorScheme = this.project.colorScheme;
    this._chartTheme = this.colorService.chartTheme(this.project.colorScheme);    
    this.filterData();
  }


}
