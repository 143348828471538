export class UserExtensions {

    public static parseClaims(userClaims?: any): any {
  
      if (!userClaims) {
        return null;
      }
  
      return {
        id: userClaims.sub,
        role: userClaims.role,
        picture: userClaims.picture,
        username: userClaims.preferred_username,
        firstname: userClaims.given_name,
        lastname: userClaims.family_name,
        fullname: userClaims.name,
        mobileNumber: userClaims.mobileNumber,
        emailAddress: userClaims.email,
      };
    }
  }
  