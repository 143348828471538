import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, map, of, Subject, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { IntegrationsList, Integration } from '../data/tables/integrations-list';
import { environment } from '../../../environments/environment';
import { getServerErrors } from '../utils/get-server-errors';

@Injectable({
  providedIn: 'root'
})
export class IntegrationsService {
  private _totalRecords$ = new BehaviorSubject<number>(0);
  get totalRecords$() {
    return this._totalRecords$.asObservable();
  }

  private _busy$: BehaviorSubject<boolean>;
  private _integrationDownloadLoading$: BehaviorSubject<boolean>;
  private _integrationList$: BehaviorSubject<Integration[]>;
  private _integration$: BehaviorSubject<Integration | null>;
  private _error$: Subject<string | null>;
  private _deleted$: Subject<boolean | null>;
  private _submitted$: Subject<boolean | null>;
  private _statusUpdated$: Subject<boolean | null>;

  get busy$() { return this._busy$.asObservable() }
  get integrationDownloadLoading$() { return this._integrationDownloadLoading$.asObservable() }
  get integrationList$() { return this._integrationList$.asObservable() }
  get integration$() { return this._integration$.asObservable() }
  get error$() { return this._error$.asObservable() }
  get deleted$() { return this._deleted$.asObservable() }
  get submitted$() { return this._submitted$.asObservable() }
  get statusUpdated$() { return this._statusUpdated$.asObservable() }

  constructor(private http: HttpClient) {    
    this._integrationList$ = new BehaviorSubject<Integration[]>([]);
    this._integration$ = new BehaviorSubject<Integration | null>(null);
    this._busy$ = new BehaviorSubject<boolean>(false);
    this._integrationDownloadLoading$ = new BehaviorSubject<boolean>(false);
    this._error$ = new Subject<string | null>()
    this._deleted$ = new Subject<boolean | null>()
    this._submitted$ = new Subject<boolean | null>()
    this._statusUpdated$ = new Subject<boolean | null>()
  }

  load(paging?: {skip: number, take:number}){
    this._busy$.next(true);
    const params = paging ? `?take=${paging.take}&skip=${paging.skip}` : '';
    return this.http.get<Integration[]>(`${environment.endpoints.api}integrations${params}`)
    .pipe(
      finalize(() => this._busy$.next(false))
    )
    .subscribe({
        next: (response: any) => {
          this._integrationList$.next(response.items)
          this._totalRecords$.next(response.totalRecords)
          const totalRecords = (response.pageCount - 1) * response.pageSize + response.items.length;
          this._totalRecords$.next(totalRecords);
        },
        error: (err:any) => {
          const error: string = getServerErrors(err);
          this._error$.next(error);
        },  
      }
    );
  }

  loadPermissions(){
    this._busy$.next(true);
    return this.http.get<Integration[]>(`${environment.endpoints.api}/integrations`)
    .pipe(
      finalize(() => this._busy$.next(false))
    )
    .subscribe({
        next: (response: Integration[]) => this._integrationList$.next(response),
        error: (err:any) => {
          const error: string = getServerErrors(err);
          this._error$.next(error);
        },       
      }
    );
  }

  fetch(id:string){
    this._integration$.next(null)
    this._busy$.next(true);
    return this.http.get<Integration>(`${environment.endpoints.api}integrations/${id}`)
    .subscribe({
        next: (response: Integration) => {         
          let _id = response[id]
          return this._integration$.next(response)
        },
        error: (err:any) => {
          const error: string = getServerErrors(err);
          this._error$.next(error);
        },        
      }
    );
  }

  delete(item:Integration){
    this._busy$.next(true);
    return this.http.delete<Integration>(`${environment.endpoints.api}integrations/${item.id}`)    
    .pipe(
      finalize(() => this._busy$.next(false))
    )
 
    
  }
  save(form: Integration): Observable<Integration> {
    this._busy$.next(true);
    this._submitted$.next(false);
    
    return this.http.post<Integration>(`${environment.endpoints.api}integrations`, form)
      .pipe(
        finalize(() => this._busy$.next(false))
      );
  }
  
  update(form: Integration){
    this._busy$.next(true);
    this._submitted$.next(false);
    
    return this.http.patch<Integration>(`${environment.endpoints.api}integrations/${form.id}`, form)  
      .pipe(
        finalize(() => this._busy$.next(false))
      );
  }
  // save(form:Integration){
  //   this._busy$.next(true);
  //   this._submitted$.next(false);
  //   return this.http.post<Integration>(`${environment.endpoints.api}integrations`, form)  
  //   .pipe(
  //     finalize(() => this._busy$.next(false))
  //   )
  //   .subscribe({
  //     next: (response: any) => {
  //       this._submitted$.next(true);
  //       this._integration$.next(response)
  //     },
  //     error: (err:any) => {
  //       const error: string = getServerErrors(err);
  //       this._error$.next(error);
  //     },      
  //     }            
  //   );
  // }
   

  // update1(form:Integration){
  //   this._busy$.next(true);
  //   this._submitted$.next(false);
  //   return this.http.patch<Integration>(`${environment.endpoints.api}integrations/${form.id}`, form)  
  //   .pipe(
  //     finalize(() => this._busy$.next(false))
  //   )
  //   .subscribe({
  //     next: (response: any) => {
  //       this._submitted$.next(true);
  //       this._integration$.next(response)
  //     },
  //     error: (err:any) => {
  //       const error: string = getServerErrors(err);
  //       this._error$.next(error);
  //     },            
  //     }
  //   );
  // }

  // reset1(id: string){
  //   this._busy$.next(true);
  //   return this.http.patch<Integration>(`${environment.endpoints.api}integrations/${id}/reset-secret`, {})  
  //   .pipe(
  //     finalize(() => this._busy$.next(false))
  //   )
  //   .subscribe({
  //       next: (response: Integration) => this._integration$.next(response),
  //       error: (error:any) => this._error$.error(error),       
  //     }
  //   );
  // }

  reset(id: string){
    this._busy$.next(true);
    
    return this.http.patch<Integration>(`${environment.endpoints.api}integrations/${id}/reset-secret`, {}) 
      .pipe(
        finalize(() => this._busy$.next(false))
      );
  }

  enable(form:Integration){
    this._statusUpdated$.next(true);
    return this.http.patch<Integration>(`${environment.endpoints.api}integrations/${form.id}/status/enable`, {})  
    .pipe(
      finalize(() => this._statusUpdated$.next(false))
    )
    .subscribe({
        next: (response: Integration) => {

          //this._integration$.next(response)
          this.fetch(response.id!)
        
        },
        error: (error:any) => this._error$.error(error),       
      }
    );
  }

  revoke(form:Integration){
    this._statusUpdated$.next(true);
    return this.http.patch<Integration>(`${environment.endpoints.api}integrations/${form.id}/status/revoke`, {})  
    .pipe(
      finalize(() => this._statusUpdated$.next(false))
    )
    .subscribe({
        next: (response: Integration) => {
          // this._integration$.next(response)        
          this.fetch(response.id!)
        },
        error: (error:any) => this._error$.error(error),       
      }
    );
  }

  download(integration){
    this._integrationDownloadLoading$.next(true);
    this.http.get<Integration>(`${integration.file['_links'].download.href}`)
    .pipe(
      finalize(() => this._integrationDownloadLoading$.next(false))
    )
    .subscribe({
        next: (response: any) => {
        
          let _res = JSON.stringify(response);
          const fileName = `${integration.file.name}`;
          const blob = new Blob([_res], { type: "application/json" });

            const downloadUrl = window.URL.createObjectURL(blob);
            const link: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
            link.href = downloadUrl;
  
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
  
            document.body.removeChild(link);
            window.URL.revokeObjectURL(downloadUrl);
        },
        error: (error:any) => {
          console.log(error)
          this._error$.error(error)

        },       
      }
    );
  }

unsubscribe(){
  this._integration$.next(null);   
}


  destroy(){   
    this._busy$.unsubscribe();
    this._integrationList$.unsubscribe();
    this._integration$.unsubscribe();      
  }
}