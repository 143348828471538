import { NgModule } from "@angular/core";
import { RouterModule, Routes, PreloadAllModules } from "@angular/router";
import { LoginComponent } from "./auth/login/login.component";
import { ContentLayoutComponent } from "./shared/components/layout/content-layout/content-layout.component";
import { FullLayoutComponent } from "./shared/components/layout/full-layout/full-layout.component";
import { content } from "./shared/routes/content-routes";
import { full } from "./shared/routes/full.routes";
import { AdminGuard } from "./shared/guard/admin.guard";

import {SamplePageModule} from './components/sample-page/sample-page.module';
import {SamplePageComponent} from './components/sample-page/sample-page.component';
import { LabsComponent } from './components/dashboard/labs-dashboard/labs-dashboard.component';
import {EntryDataComponent} from './entry-data/entry-data.component'

const routes: Routes = [
  {
    path: "",
    redirectTo: "/project-list",
    pathMatch: "full",
  },
  {
    path: "auth/login",
    component: LoginComponent,
  },
  {
    path: "",
    component: ContentLayoutComponent,
   // canActivate: [AdminGuard],
    children: content,
  },
  { path: 'entry-data', component: EntryDataComponent },
  // {
  //   path: "share/:id/dashboard",
  //   component: FullLayoutComponent,
 
  // },
  // {
  //   path: "dashboard/share/:id",
  //   component: ContentLayoutComponent,
  //   canActivate: [AdminGuard],
  //   // children: content,
  // },
  {
    path: "share/dashboard/:id",
    component:LabsComponent,
  },
  // {
  //   path: "**",
  //   redirectTo: "/error"
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      anchorScrolling: "enabled",
      scrollPositionRestoration: "enabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}