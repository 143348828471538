import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import * as chartData from '../../../../shared/data/chart/google-chart';
import { ColorService } from '../../../../shared/services/theme.service';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-hourly-chart',
  templateUrl: './hourly-chart.component.html',
  styleUrls: ['./hourly-chart.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HourlyChartComponent implements OnInit {
  @Input() data;
  @Input() loading;
  @Input() project;
  lineChart:any;
  chartData;
  private _chartTheme = [];
  colorScheme: string;
  constructor(private colorService: ColorService, private route: ActivatedRoute) { }

  

  private filterData(){
    // this.chartData = this.data.map(res => {    
    //   return [res.sampledAt, res.total]
    // });
    
    // this.chartData.unshift(['Hour', 'Value']);

    this.lineChart = {
      chartType: 'LineChart',
      dataTable:this.data,
      options: {
        chart: {
          title: '',
          subtitle: ''
        },
        curveType: 'function',
        lineWidth:4,
        title: '',
        vAxis: { minValue: 0 , format: 'short' },
        fontName: "Roboto, 'Helvetica Neue', 'sans-serif'",
        fontSize: 13,
        subtitle: 'in millions of dollars (USD)',
        colors: this._chartTheme,
        fullWidth: true,    
        height: 400,
        backgroundColor:'transparent',
        chartArea:{left:'4.8%',top:'5%',width:'93%',height:'70%'},
        legend: { position: 'bottom' }
      
      },
    };
  }
  

  ngOnInit() {
    if(!this.data) return;
    this.setChartTheme();
  }

  private setChartTheme(){
    this.colorScheme = this.project.colorScheme;
    this._chartTheme = this.colorService.chartTheme(this.project.colorScheme);    
    this.filterData();
  }
}
