import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import * as chartData from '../../../../shared/data/chart/google-chart';
import { ColorService } from '../../../../shared/services/theme.service';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-consumer-optin-chart',
  templateUrl: './consumer-optin-chart.component.html',
  styleUrls: ['./consumer-optin-chart.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ConsumerOptInChartComponent implements OnInit {
  @Input() data;
  @Input() project;
  @Input() loading;
  private _chartTheme = [];
  lineChart:any;
  chartData;

  colorScheme: string;
  constructor(private colorService: ColorService, private route: ActivatedRoute) { }

  private filterData(){
    // this.chartData = this.data.map(res => {    
    //   return [res.sampledAt, res.total]
    // });
    
    // this.chartData.unshift(['Date', 'Value']);
    this.chartData = this.data;

    this.lineChart = {
      chartType: 'LineChart',
      dataTable: this.chartData,
      options: {
        chart: {
          title: '',
          subtitle: ''
        },
        title: '',
        curveType: 'function',
        lineWidth:4,
        fontName: "Roboto, 'Helvetica Neue', 'sans-serif'",
        fontSize: 14,
        vAxis: { minValue: 0 , format: 'short' },
        subtitle: 'in millions of dollars (USD)',
        colors: this._chartTheme,
        fullWidth: true,    
        height: 400,
        backgroundColor:'transparent',

        legend: { position: 'bottom' },
        chartArea:{left:'4.8%',top:'5%',width:'93%',height:'70%'},  
      
      },
    };
  }

  ngOnInit() {
    if(!this.data) return;
    this.setChartTheme();
  }

  private setChartTheme(){
    this.colorScheme = this.project.colorScheme;
    this._chartTheme = this.colorService.chartTheme(this.project.colorScheme);    
    this.filterData();
  }
}
