<div class="container-fluid my-custom-container">
  <div class="row">
      <div class="col-sm-12">
          <div class="card">
              <div [ngStyle]="{'background-color': colorScheme}" class="daily-header" ><h6 class="m-0">Daily</h6></div>
              @if((data?.length > 1)){
              <google-chart [data]="lineChart" ></google-chart>
              }@else{
              <h4 class="text-center p-t-25 p-b-25">No Available Data Found.</h4>
              }
          </div>
      </div>
  </div>
</div>
