import { HttpErrorResponse } from "@angular/common/http";

export function getServerErrors(serverError: HttpErrorResponse) {  
  if (typeof serverError.error === 'object') {
    try {
      if (serverError.error && !serverError.error.hasOwnProperty('errors')) {
        if (Array.isArray(serverError.error)) {
          return serverError.error.map((e: any) => e.message).join('\n');
        }
        const errorKeys = Object.keys(serverError.error);
        return errorKeys.map(key => serverError.error[key].join('\n')).join('\n');
      } else if (serverError.error && serverError.error.errors) {
        if (Array.isArray(serverError.error.errors)) {
          return serverError.error.errors.map((e: any) => e.message).join('\n');
        }
        const errorKeys = Object.keys(serverError.error.errors);
        return errorKeys.map(key => serverError.error.errors[key].join('\n')).join('\n');
      } else if (serverError.error.detail) {
        return serverError.error.detail;
      }

      return serverError.error.title;

    } catch (e) {
      return serverError.error === 'string' ? serverError.error : serverError.message;
    }
  }
  return serverError.error === 'string' ? serverError.error : serverError.message;
}
