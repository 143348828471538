import { Component, OnInit, ViewEncapsulation, OnDestroy } from "@angular/core";
import * as chartData from "../../../shared/data/dashboard/default";
import { StatisticsService } from '../../../shared/services/statistics.service';
import { ProjectService } from '../../../shared/services/project.service';
import { Observable, forkJoin, flatMap,map } from "rxjs";
import { Router, Route, ActivatedRoute,ParamMap  } from '@angular/router';
import { withHttpTransferCacheOptions } from "@angular/platform-browser";
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { NavService } from "src/app/shared/services/nav.service";

declare var require: any;
// var Knob = require('knob')// browserify require
import { ColorService } from '../../../shared/services/theme.service';
var primary = localStorage.getItem("primary_color") || "#4466f2";
var secondary = localStorage.getItem("secondary_color") || "#1ea6ec";
import { ChangeDetectorRef } from '@angular/core';
@Component({
  selector: "app-labs",
  templateUrl: "./labs-dashboard.component.html",
  styleUrls: ["./labs-dashboard.component.scss"],
 // encapsulation: ViewEncapsulation.None,
})
export class LabsComponent implements OnInit, OnDestroy {

  private _projectId: string = '';
   _token = '';
  _isPublic = false;
  _nav;
  activeTab = 1;
  _test;
  hasExports: boolean = false;
  project$: Observable<any>;
  expired$:Observable<boolean>;

  entriesByDay$: Observable<any>;
  entriesByHour$: Observable<any>;
  dataEnrichmentByDay$: Observable<any>;
  dataEnrichmentSummary$: Observable<any>;
  recruitmentByDay$: Observable<any>;
  recruitmentSummary$: Observable<any>;
  rewardsByDay$: Observable<any>;
  rewardsSummary$: Observable<any>;
  statusByDay$: Observable<any>;
  statusSummary$: Observable<any>;
  subscriptionStatusByDay$: Observable<any>;
  subscriptionStatusSummary$: Observable<any>;
  typeByDay$: Observable<any>;
  typeSummary$: Observable<any>;
  // busy$: Observable<any>;
  entriesByDayLoading$: Observable<boolean>;
  entriesByHourLoading$: Observable<boolean>;
  statusByDayLoading$: Observable<boolean>;
  statusSummaryLoading$: Observable<boolean>;
  typeByDayLoading$: Observable<boolean>;
  typeSummaryLoading$: Observable<boolean>;
  rewardsByDayLoading$: Observable<boolean>;
  rewardsSummaryLoading$: Observable<boolean>;
  dataEnrichmentSummaryLoading$: Observable<boolean>;
  recruitmentByDayLoading$: Observable<boolean>;
  recruitmentSummaryLoading$: Observable<boolean>;
  subscriptionStatusByDayLoading$: Observable<boolean>;
  subscriptionStatusSummaryLoading$: Observable<boolean>;
  projectLoading$:Observable<boolean>;
  colorScheme: string;

  constructor(private statisticsService: StatisticsService,
              private route: ActivatedRoute,
              private projectService: ProjectService,
              private colorService: ColorService,
              private NavService: NavService,
              private cdr: ChangeDetectorRef
              ) 
  {

    

    this._projectId = this.route.snapshot.paramMap.get('id')!;
    this.project$ = this.projectService.project$;


    this.entriesByDay$ = this.statisticsService.entriesByDay$;
    this.entriesByHour$ = this.statisticsService.entriesByHour$;
    this.dataEnrichmentByDay$ = this.statisticsService.dataEnrichmentByDay$;
    this.dataEnrichmentSummary$ = this.statisticsService.dataEnrichmentSummary$;
    this.recruitmentByDay$ = this.statisticsService.recruitmentByDay$;
    this.recruitmentSummary$ = this.statisticsService.recruitmentSummary$;
    this.rewardsByDay$ = this.statisticsService.rewardsByDay$;
    this.rewardsSummary$ = this.statisticsService.rewardsSummary$;
    this.statusByDay$ = this.statisticsService.statusByDay$;
    this.statusSummary$ = this.statisticsService.statusSummary$;
    this.subscriptionStatusByDay$ = this.statisticsService.subscriptionStatusByDay$;
    this.subscriptionStatusSummary$ = this.statisticsService.subscriptionStatusSummary$;
    this.typeByDay$ = this.statisticsService.typeByDay$;
    this.typeSummary$ = this.statisticsService.typeSummary$;

    this.entriesByDayLoading$ = this.statisticsService.entriesByDayLoading$;
    this.entriesByHourLoading$ = this.statisticsService.entriesByHourLoading$;
    this.dataEnrichmentByDay$ = this.statisticsService.dataEnrichmentByDay$;
    this.dataEnrichmentSummaryLoading$ = this.statisticsService.dataEnrichmentSummaryLoading$;
    this.recruitmentByDayLoading$ = this.statisticsService.recruitmentByDayLoading$;
    this.recruitmentSummaryLoading$ = this.statisticsService.recruitmentSummaryLoading$;
    this.rewardsByDayLoading$ = this.statisticsService.rewardsByDayLoading$;
    this.rewardsSummaryLoading$ = this.statisticsService.rewardsSummaryLoading$;
    this.statusByDayLoading$ = this.statisticsService.statusByDayLoading$;
    this.statusSummaryLoading$ = this.statisticsService.statusSummaryLoading$
    this.subscriptionStatusByDayLoading$ = this.statisticsService.subscriptionStatusByDayLoading$;
    this.subscriptionStatusSummaryLoading$ = this.statisticsService.subscriptionStatusSummaryLoading$;
    this.typeByDayLoading$ = this.statisticsService.typeByDayLoading$;
    this.typeSummaryLoading$ = this.statisticsService.typeSummaryLoading$;
    this.projectLoading$ = this.projectService.busy$;

    this.expired$ = this.statisticsService.expired$;

    this.project$.subscribe(res => {  
      if(!res)return; 
      this._nav = res;
      return res.name;
    });

    this.expired$.subscribe(res => {  


      // if(!res)return; 
      // this._nav = res;
      // return res.name;
    });
  }

  private load() {
    const _queryParam = new URLSearchParams(window.location.search);
    if(_queryParam.has('token')) this._token = _queryParam.get('token')!;
    if(this._token) this._isPublic = true;

    this.projectService.fetch(this.route.snapshot.paramMap.get('id')!,this._token);

    this.statisticsService.fetchEntriesByDay(this._projectId, true, this._token);
    this.statisticsService.fetchEntriesByHour(this._projectId, true,this._token);
    this.statisticsService.fetchDataEnrichmentByDay(this._projectId, true,this._token);
    this.statisticsService.fetchDataEnrichmentSummary(this._projectId, false,this._token);
    this.statisticsService.fetchRecruitmentByDay(this._projectId, true,this._token);
    this.statisticsService.fetchRecruitmentSummary(this._projectId, false,this._token);
    this.statisticsService.fetchRewardsByDay(this._projectId, true,this._token);
    this.statisticsService.fetchRewardsSummary(this._projectId, true,this._token);
    this.statisticsService.fetchStatusByDay(this._projectId, true,this._token);
    this.statisticsService.fetchStatusSummary(this._projectId, true,this._token);
    this.statisticsService.fetchSubscriptionStatusByDay(this._projectId, true,this._token);
    this.statisticsService.fetchSubscriptionStatusSummary(this._projectId, false,this._token);
    this.statisticsService.fetchTypeByDay(this._projectId, true,this._token);
    this.statisticsService.fetchTypeSummary(this._projectId, false,this._token);
    this.projectService.getProjectEntries(this._projectId, true, this._token);
  }

  onNavChange(changeEvent: NgbNavChangeEvent) {
    sessionStorage.setItem('config-nav', changeEvent.nextId)


  }
  getInitials(name: string): string {
    if (!name) return ''; 
    let initials = name.trim().substring(0, 2); 
    return initials.toUpperCase(); 
  }

  ngOnInit() {
    this.load()
    this.colorService.currentColorScheme.subscribe(color => {
      this.colorScheme = color;
    });

    this.projectService.hasExports$.subscribe(hasExports => {
      this.hasExports = hasExports;
      console.log('Received hasExports:', hasExports);  
    });
  
    this.projectService.exportList(this._projectId);
    
  }

  ngOnDestroy(): void {this.statisticsService.destroy(); this.projectService.destroy();}
}