<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-body">
          <!-- @if(data){<h4>{{data.name}} Integration</h4>} -->
          <form  class="theme-form" novalidate [formGroup]="drawForm" (ngSubmit)="onFormSubmit()" role="form">
            <div *ngIf="isLoading" class="loading-overlay">
              <mat-spinner [diameter]="30"></mat-spinner>
              <div class="loading-text">{{ loadingMessage }}</div>
            </div>
            <div [class.disabled-form]="formDisabled"  class="form-group">
              <label class="col-form-label pt-0"><strong>What would you like to call this draw?</strong></label>
              <input [class.disabled-form]="formDisabled"   [class.disabled-input]="formDisabled" class="form-control" type="text" aria-describedby="Draw name"
                placeholder="Add a name"
                formControlName="name" [ngClass]="{'validation-error': submitted && drawForm.controls['name'].invalid }" />
            </div>
            <div [class.disabled-form]="formDisabled"  class="form-group">              
              <label class="col-form-label pt-0"><strong>What type of draw is this?</strong></label>
              <select  formControlName="drawType" id="drawType" name="drawType" class="form-control digits"
                        [ngClass]="{'validation-error': submitted && drawForm.controls['drawType'].invalid }">
                        <option value="" selected disabled>Select a draw type</option>
                        @for(drawtype of projectDrawType$ | async; track drawtype){
                        <option [value]="drawtype">{{drawtype}}</option>
                        }
              </select>
            </div>

            <label [class.disabled-form]="formDisabled"  class="col-form-label pt-0"><strong>What period do you want to include entries?</strong></label>
            <div [class.disabled-form]="formDisabled"  class="d-flex">
              <div class="d-flex col-md-6" formGroupName="configuration">
                
                <div class="mutliple-datepicker">
                  <div class="input-group">                     
                    <input class="form-control" placeholder="Start Date" id="dp" name="dp" [displayMonths]="1"
                      ngbDatepicker #k="ngbDatepicker" (ngModelChange)="dateChange()"
                      [navigation]="navigation" [outsideDays]="outsideDays" [showWeekNumbers]="showWeekNumbers"                      
                      [formControl]="startDate" 
                      format="dd MMMM yyyy"                                                  
                      [minDate]="{year: 2020, month: 1, day: 1}"
                      [maxDate]="{year: 2050, month: 12, day: 31}" style="font-size: inherit;"
                      [ngClass]="{'validation-error': submitted && startDate.invalid }"
                      >
                    <div class="input-group-append">
                      <button class="btn calendar btn btn-primary" (click)="k.toggle()" type="button"><i
                          class="fa fa-calendar"></i></button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex col-md-6" formGroupName="configuration">
                <div class="mutliple-datepicker">
                  <div class="input-group">
                      <input class="form-control" placeholder="End Date" name="ep" [displayMonths]="1"
                      ngbDatepicker #m="ngbDatepicker" readonly (ngModelChange)="dateChange()"
                      [navigation]="navigation" [outsideDays]="outsideDays" [showWeekNumbers]="showWeekNumbers"                      
                      [formControl]="endDate"
                      [minDate]="{year: 2020, month: 1, day: 1}"
                      [maxDate]="{year: 2050, month: 12, day: 31}"  style="font-size: inherit;"
                      [ngClass]="{'validation-error': submitted && endDate.invalid }"
                      >
                    <div class="input-group-append">
                      <button class="btn calendar btn btn-primary" (click)="m.toggle()" type="button"><i
                          class="fa fa-calendar"></i></button>
                    </div>                             
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="form-group">
              <label for="drawTypeSelect">Draw Type:</label>
              <select class="form-control" formControlName="numberOfEntries">
                  <option *ngFor="let type of drawTypes$ | async" [value]="type.id">{{ type.name }}</option>
              </select>
          </div> -->
          <div [class.disabled-form]="formDisabled"  class="form-group" formGroupName="configuration">
            <label class="col-form-label pt-0"><strong>How many winners would you like to draw?</strong></label>
            <input class="form-control" type="number" aria-describedby="winnerNumberHelp"
              placeholder="Enter winner amount"
                formControlName="numberOfEntries" min="1"
                 [ngClass]="{'validation-error': submitted && configuration.controls['numberOfEntries'].invalid }" />
         
                 <hr style=" margin-bottom: 2px; ">
                 <div *ngIf="metadataFields.length > 0 || profileProperties.length > 0" class="d-flex align-items-center">
                  <input [checked]="showAdvancedFilters" type="checkbox" class="me-2" (change)="toggleAdvancedFilters($event)"/>
                  <label style="margin-top: 5px;" class="form-check-label">Enable Advanced Filters</label>
                </div>

         <div style="margin-top: 0; " *ngIf="showAdvancedFilters" class="advanced-filters-container">
            <div  *ngIf="profileProperties.length > 0">
              <div formArrayName="demographicFilters"  class="mb-3"  class="demographic-filters" style="border: 1px solid rgb(185, 185, 185); padding: 10px;">
                <div class="d-flex justify-content-between align-items-center">
                  <h6 class="filter-header" >DEMOGRAPHIC FILTERS</h6>
                  <a class="text-primary" (click)="addDemographicFilter()">+ Add Filter</a>
                </div>
                  <table  class="table table-bordered">
                    <thead  *ngIf="showDemographicTable">
                      <tr>
                        <th>Field</th>
                        <th>Values</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let filterGroup of demographicFilters.controls; let i = index" [formGroupName]="i">
                        <td>
                          <select (change)="onPropertyChange($event, i)" formControlName="key" class="form-select">
                            <option *ngFor="let property of profileProperties" [value]="property">{{ property }}</option>
                            
                          </select>
                        </td>
                        <td>
                          <select (change)="onValuesChange($event, i)" multiple class="form-select">
                            <option *ngFor="let value of demographicValuesMap[filterGroup.get('key')?.value] || [filterGroup.get('values')?.value]" [value]="value">{{ value }}</option>
                          </select>
                        </td>
                        <td>
                          <button style="border: 1px solid white;" class="text-danger demographic-button" (click)="removeDemographicFilter(i)" >Delete</button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
              </div>
            </div>
          
          
          <div style="margin-top: 20px;"></div>
            <!-- MetaData Start -->
            <div *ngIf="metadataFields.length > 0" class="metadata-filters" >
              <div formArrayName="entryMetadataFilters" class="mb-3" style="border: 1px solid  rgb(185, 185, 185); padding: 10px" >
                <div class="d-flex justify-content-between align-items-center">
                  <h6 class="filter-header" >METADATA FILTERS</h6>
                  <a (click)="addMetadataFilter()" class="text-primary" >+ Add Filter</a>
                </div>
                <table *ngIf="showMetadataTable" class="table table-bordered mb-0">
                  <thead>
                    <tr>
                      <th>Field</th>
                      <th>Values</th>
                      <th>Action</th>
                    </tr> 
                  </thead>
                  <tbody>
                    <tr *ngFor="let meta of entryMetadataFilters.controls; let j = index" [formGroupName]="j">
                      <td>
                        <select formControlName="key" (change)="onFieldSelect($event,j)" class="form-select">
                          <option *ngFor="let field of metadataFields" [value]="field">{{ field }}</option>
                        </select>
                      </td>
                      <td>
                        <select (change)="ValuesChange($event, j)"  multiple class="form-select">
                          <option *ngFor="let value of metadataValuesMap[meta.get('key')?.value] || [meta.get('values')?.value] || [] " [value]="value">{{ value }}</option>
                        </select>
                      </td>
                      <td> 
                        <button style="border: 1px solid white;" (click)="removeMetadataFiter(j)" class="text-danger">Delete</button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div> 
          </div> 
        </div>
              
                  <hr style=" margin-top: -17px; ">
                  <div class="d-flex justify-content-end align-items-left ">
                    <div *ngIf="!showEditAndDraws">
                      <button type="button" class="btn btn-light me-3" (click)="onCancel()">Cancel</button>
                      <button type="submit" class="btn btn-primary" [disabled]="drawForm.disabled" [disabled]="isSubmitting">Next</button>
                    </div>
                    <div *ngIf="showEditAndDraws" >
                      <button type="button" class="btn btn-light" (click)="enableForm()">Edit</button>
                      <!-- Conditional display of Draw Winners or Cancel button -->
                      <button 
                      [ngClass]="{'btn-warning': buttonWarning}" 
                      *ngIf="poolConsumerData !== 0" 
                      [disabled]="isSubmitting" 
                      type="button" 
                      class="btn btn-primary" 
                      (click)="performDraw()">
                      <ng-container *ngIf="isSubmitting; else buttonText">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Loading...
                      </ng-container>
                      <ng-template #buttonText>
                        Draw {{ smallestValue }} Winners
                      </ng-template>
                    </button>
                    
                      <button *ngIf="poolConsumerData === 0" type="button" class="btn btn-primary" (click)="onCancel()"> 
                        Cancel
                      </button>
                      <div style="display: inline-block;width: 100%;position: relative;height: 0;"></div>
                    </div>
                 
                  </div>
                  <div *ngIf="showEditAndDraws" class="d-flex justify-content-end">
                    Your draw has <span class="pool-text-style">&nbsp;{{ poolEntryData }}&nbsp;</span> eligible entries, from
                    <span class="pool-text-style">&nbsp;{{ poolConsumerData }}&nbsp;</span> consumers
                  </div>
                </form>
             </div>
          </div>
      </div>
   </div>
</div>

