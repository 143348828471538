import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chart-load',
  templateUrl: './chart-loader.component.html',
  styleUrls: ['./chart-loader.component.scss']
})
export class ChartLoaderComponent implements OnInit {

  constructor() {}

  ngOnInit() {}

}
